@import '../../../../vars';

.PageContentBlock {
  padding: 48px $spacer-large;
  border-radius: $radius-xxlarge;
  background-color: $color-white;

  & + & {
    margin-top: $spacer-medium;
  }

  @include mobile {
    padding: $spacer-large $spacer-small;
    border-radius: 0;
  }
}