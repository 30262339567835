@mixin SetInputColor($color) {
  color: $color;

  & ~ .Input__leftIcon, & ~ .Input__rightIcon {
    color: $color;
  }
}

@mixin InputBaseStyles {
  @include caption_large_medium;
  @include SetInputColor($color-text-primary);
  box-sizing: border-box;
  width: 100%;
  padding: $spacer-small-2x $spacer-small-x;
  transition: border-color 0.2s ease;
  border: 1px solid $color-tag-2;
  border-radius: $radius-small;
  background-color: $color-surface-light;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &::placeholder {
    color: $color-text-secondary;
  }

  &:hover {
    border-color: $color-text-secondary;
  }

  &:focus {
    border-color: $color-primary;
    outline: 0;
  }

  &:disabled {
    @include SetInputColor($color-tag-2);
    background-color: $color-background-gray;

    &::placeholder {
      color: $color-tag-2;
    }

    &:hover {
      border-color: $color-tag-2;
    }
  }

  &[type="search"] {
    &::-ms-clear, &::-ms-reveal {
      display: none;
      width : 0;
      height: 0;
    }

    &::-webkit-search-decoration, &::-webkit-search-cancel-button,
    &::-webkit-search-results-button, &::-webkit-search-results-decoration {
      display: none;
    }
  }
}

@mixin InputInvalidStyles {
  border-color: $color-error;
}