@mixin NumberSliderSizeMixin($verticalPadding, $horizontalPadding, $innerPadding, $buttonsPadding) {
  padding: $verticalPadding $horizontalPadding;

  .NumberSlider__decrement, .NumberSlider__increment {
    padding: $buttonsPadding;
  }

  .NumberSlider__value {
    padding: 0 $innerPadding;
  }
}