@import '../../../../vars';

.Tag {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  border-radius: $radius-xxlarge;

  & + & {
    margin-left: $spacer-small-3x;
  }
}

.Tag_size_small {
  @include caption_small_medium;
  padding: $spacer-small-3x $spacer-small-2x;
}

.Tag_size_medium {
  @include caption_small_medium;
  padding: $spacer-small-2x $spacer-medium;
}

.Tag_size_large {
  @include header_h3_bold;
  padding: $spacer-small-2x $spacer-small;
}

.Tag_variant_normal {
  color: $color-text-primary;
  background-color: $color-tag-2;
}

.Tag_variant_primary {
  color: $color-primary;
  background-color: $color-tag-1;
}

.Tag_variant_amount {
  color: $color-background-dark;
  border-radius: $radius-small;
  background: $color-card-light;
}

.Tag_variant_secondary {
  color: $color-text-on-accent;
  background: $color-secondary;
}

.Tag_variant_foil {
  background: $color-card-foil;
}

.Tag__amount {
  margin-left: $spacer-small-2x;
}