@import '../../../../vars';

.OrderLayout {
  @include adaptiveWidth;
  margin: 0 auto;
  padding: $spacer-large-x $spacer-large $spacer-large-x;

  @include lte_tabletLand {
    padding: $spacer-large-x $spacer-large $spacer-large-x;
  }

  @include mobile {
    width: 100%;
    padding: $spacer-small-x 0;
  }
}

.OrderLayout__backLink {
  height: 24px;
  margin-bottom: $spacer-medium;

  @include mobile {
    margin-bottom: $spacer-small;
  }
}

.OrderLayout__title {
  @include header_h2_medium;
  margin-top: 0;
}

.OrderLayout__annotation {
  @include caption_medium_regular;
  margin-bottom: $spacer-small;
  padding: $spacer-small-2x;
  text-align: center;
  color: rgba($color-surface-dark, 0.4);
  border-radius: $radius-large;
  background-color: $color-white;

  @include mobile {
    border-radius: 0;
  }
}
.OrderLayout__contentLayout {
  display: grid;
  grid-template-columns: 1fr 280px;
  grid-template-rows: 1fr;
  grid-column-gap: $spacer-medium;
}

.OrderLayout__content {
  > div + div {
    margin-top: $spacer-small;
  }
}

@include lte_tabletLand {
  .OrderLayout__contentLayout {
    display: block;
  }
}

@include mobile {
  .OrderLayout__title {
    @include header_h4_medium;
    padding-left: $spacer-small;
  }
}
