@import '../../vars';

.CardPage {
}

.CardPage__backLink {
  margin: $spacer-medium 0;

  @include mobile {
    margin-top: $spacer-small;
    margin-bottom: 0;
  }
}

.CardPage__moreCard {
  @include ScrollerGrid(10, 288px);
}
