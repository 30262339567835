@import '../../../../vars';

.BackButton {
  @include buttonReset;
  @include caption_large_medium;
  display: inline-flex;
  align-items: center;
  padding: 0;
  transition: color $transition-duration $transition-function;
  color: $color-text-secondary;

  &:hover, &:focus {
    color: $color-text-primary;
  }
}

.BackButton__icon {
  margin-right: $spacer-small-2x;
}