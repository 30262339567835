@import '../../../../vars';
@import './InputMixins';

.Input {
  position: relative;
  width: 100%;
  transition: width $transition-duration $transition-function;
}

.Input__leftIcon, .Input__rightIcon {
  position: absolute;
  line-height: $font_size_12;
}

.Input__leftIcon {
  top: $spacer-small-2x;
  left: $spacer-small-x;
}

.Input__rightIcon {
  top: $spacer-small-2x;
  right: $spacer-small-x;
}

.Input__control {
  @include InputBaseStyles;
  margin: 0;
  line-height: 22px;
}

.Input__control_leftIcon {
  padding-left: $spacer-small-x * 3;
}

.Input__control_rightIcon {
  padding-right: $spacer-small-x * 3;
}

.Input_invalid {
  .Input__control {
    @include InputInvalidStyles;
  }
}

.Input_focused {

}

.Input_phone {
  :global {
    .PhoneInputInput {
      padding: 0;
      border: none;
      outline: 0;
    }
  }
}