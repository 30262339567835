@import '../../../vars';

.CardInfo {
  display: grid;
  margin-bottom: $spacer-large-2x;
  padding: 48px;
  border-radius: $radius-xxlarge;
  background-color: $color-white;
  grid-template-columns: 384px 1fr;
  grid-template-rows: auto;
  grid-column-gap: 100px;

  @include mobile {
    margin: ($spacer-small) (-$spacer-small);
    padding: $spacer-medium $spacer-small;
    border-radius: 0;
  }
}

.CardInfo__image {
  margin: 0 auto;
  grid-area: 1 / 1 / 3 / 2;
}

.CardInfo__cardImage {
  position: sticky;
  top: 100px;
}

.CardInfo__contentTitle {
  display: flex;
  flex-direction: column;
  color: rgba($color-surface-dark, 0.8);
  grid-area: 1 / 2 / 2 / 3;
}

.CardInfo__set {
  margin-bottom: $spacer-small;
}

.CardInfo__setIcon {
  width: auto;
  height: 12px;
  margin-left: $spacer-small-2x;
  opacity: 0.4;
}

.CardInfo__title {
  display: flex;
  margin-top: $spacer-small;
  margin-bottom: $spacer-small;
}

.CardInfo__name {
  @include header_h4_medium;
  color: $color-surface-dark;
}

.CardInfo__foilImage {
  margin-left: $spacer-small-x;
}

.CardInfo__contentCard {
  grid-area: 2 / 2 / 3 / 3;
}

.CardInfo__tags {
  > * {
    margin-right: $spacer-small-2x;
    margin-bottom: $spacer-small-2x;

    @include mobile {
      margin-right: 0;
      margin-bottom: 0;
    }
  }
  .CardInfo__foil {
    background: $color-card-foil;
  }

  .CardInfo__showcase {
    color: $color-primary;
    background-color: $color-tag-1;
  }
}

.CardInfo__languages {
  margin-top: $spacer-medium;
}

.CardInfo__title + .CardInfo__languages {
  margin-top: $spacer-small-2x;
}

.CardInfo__stateTitle {
  @include caption_large_regular;
  margin-bottom: $spacer-small-3x;
}
.CardInfo__state {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $spacer-medium;

  > * {
    margin-top: $spacer-small-2x;
    margin-right: $spacer-small-x;

    @include mobile {
      margin-right: $spacer-small-2x;
    }
  }
}
.CardInfo__availability {
  @include caption_large_regular;
  margin-bottom: $spacer-small-2x;

  span {
    @include caption_large_medium;
    margin-left: $spacer-small-2x;
  }
}
.CardInfo__price {
  @include header_h3_bold;
  margin-top: $spacer-medium;
  margin-bottom: $spacer-large;
  color: $color-primary;
}

.CardInfo__price_secondary {
  color: $color-secondary;
}

.CardInfo__addCard {
  min-width: 216px;
  margin-top: $spacer-large;
  margin-bottom: $spacer-large-x;
}

.CardInfo__cartCount {
  width: fit-content;
  min-width: 216px;

  @include mobile {
    width: 100%;
  }
}

.CardInfo__notAvailableTitle {
  @include caption_large_medium;
  color: rgba($color-surface-dark, 0.8);

  & + & {
    margin-top: $spacer-small-2x;
  }

  &:last-of-type {
    margin-bottom: $spacer-large;
  }
}

.CardInfo__annotation, .CardInfo__rarity, .CardInfo__availableLanguages {
  @include caption_large_bold;
  margin-bottom: $spacer-small;
  color: rgba($color-surface-dark, 0.8);
  span {
    @include caption_large_regular;
  }
}

.CardInfo__typesAnnotation {
  color: rgba($color-surface-dark, 0.8);
}

.CardInfo__rarity, .CardInfo__availableLanguages {
  margin-top: 48px;
}

.CardInfo__availableLanguages + .CardInfo__rarity {
  margin-top: $spacer-small-x;
}

.CardInfo__typeLine {
  margin-bottom: $spacer-small-2x;
}

.CardInfo__description {
  @include caption_large_regular;
  max-width: 414px;
  margin-bottom: $spacer-small;
  color: rgba($color-surface-dark, 0.8);
}

.CardInfo__illustrator {
  @include caption_large_bold;
  margin-bottom: $spacer-small;
  color: rgba($color-surface-dark, 0.8);
}

.CardInfo__illustratorLink {
  @include caption_large_regular;
  margin: 0 4px;
  text-decoration: underline;

  &::after {
    content: ',';
  }

  &:last-of-type::after {
    display: none;
  }

  @include focusVisibleAndHover {
    color: $color-primary;
  }
}

@include tabletLand {
  .CardInfo {
    grid-column-gap: 40px;
  }
}

@include tablet {
  .CardInfo {
    grid-template-columns: 158px 1fr;
    grid-template-rows: auto;
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }

  .CardInfo__image {
    grid-area: 1 / 1 / 2 / 2;
  }
  .CardInfo__contentTitle {
    grid-area: 1 / 2 / 2 / 3;
  }
  .CardInfo__contentCard {
    grid-area: 2 / 1 / 3 / 3;
  }
}

@include mobile {
  .CardInfo {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-row-gap: $spacer-small;
  }

  .CardInfo__image {
    max-width: 184px;
    grid-area: 1 / 1 / 2 / 2;
  }
  .CardInfo__contentTitle {
    grid-area: 2 / 1 / 3 / 2;
  }
  .CardInfo__contentCard {
    grid-area: 3 / 1 / 4 / 2;
  }

  .CardInfo__tags {
    order: -1;
    margin-bottom: $spacer-small-x;
  }
}
