@import "./baseColors";

@mixin shadow_ondark_accent {
  box-shadow: 0 2px 24px rgba($color-primary, .66);
}

@mixin shadow_onlight_accent {
  box-shadow: 0 4px 16px rgba($color-primary, .4);
}

@mixin shadow_onlight_warm {
  box-shadow: 0 12px 38px $color-shadow-warm;
}

@mixin shadow_onlight_cold {
  box-shadow: 0 12px 38px rgba($color-shadow-cold, .16);
}

@mixin shadow_dropdown {
  box-shadow: 0 1px 12px rgba($color-shadow-dropdown, .2);
}

@mixin shadow_inner {
  box-shadow: inset 0 1px 3px rgba($color-shadow-inner, .8);
}

@mixin blur {
  background-color: rgba($color-blur-background, .04);
  backdrop-filter: blur(32px);
}
