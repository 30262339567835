@import "../../../../vars";

.MainBanner {
  overflow: hidden;
  width: 100%;
  height: 520px;
  background-color: $color-surface-light;

  @include lte_tablet {
    position: relative;
    height: 420px;
  }
}

.MainBanner__content {
  @include adaptiveWidth;
  position: relative;
  margin: 0 auto;
}

.MainBanner__title {
  @include header_h1_bold;
  width: 550px;
  padding-top: 90px;
  color: $color-text-on-accent;

  @include tablet {
    @include header_h3_bold;
    width: 400px;
    padding-top: $spacer-large-2x;
  }

  @include mobile {
    @include header_h4_medium;
    width: 100%;
    padding-top: $spacer-large-3x;
    font-weight: 700;
  }
}

.MainBanner__description {
  @include caption_large_regular;
  width: 415px;
  margin-top: $spacer-small;
  color: $color-text-on-accent;

  @include tablet {
    width: 400px;
  }

  @include mobile {
    width: 100%;
  }
}

.MainBanner__actionButton {
  display: block;
  margin-top: $spacer-large-x;
}

.MainBanner__backgroundImage {
  position: absolute;
  right: 0;

  @include lte_tablet {
    height: 100%;
  }

  @include mobile {
    right: -150px;
  }
}

.MainBanner__firstCard {
  position: absolute;
  top: 30px;
  right: 400px;
  transform: rotate(-5deg);

  @include tabletLand {
    right: 0;
  }

  @include tablet {
    right: -100px;
  }
}

.MainBanner__secondCard {
  position: absolute;
  top: 60px;
  right: 200px;
  transform: rotate(7deg);

  @include tabletLand {
    right: -200px;
  }

  @include tablet {
    display: none;
  }
}

.MainBanner__starDecoration {
  position: absolute;
  right: 220px;
  transform: rotate(-17.25deg) scaleX(0.94) scaleY(1.4);

  @include tabletLand {
    right: -150px;
  }

  @include tablet {
    top: -150px;
    right: -200px;
    width: 507px;
    height: 754px;
  }
}