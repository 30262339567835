@import '../../../../vars';

$dropdown-top-offset: -20px;
$dropdown-left-offset: -24px;

.Dropdown {
  position: relative;
  display: inline-block;
}

.Dropdown__control {
  @include buttonReset;
  @include caption_large_medium;
  display: flex;
  align-items: center;
  transition: color $transition-duration $transition-function;
  color: $color-text-secondary;

  &:hover, &:focus {
    color: $color-text-primary;
  }
}

.Dropdown__icon_arrow {
  margin-left: $spacer-small-x;
}

.Dropdown__list {
  @include shadow_dropdown;
  position: absolute;
  z-index: $z-index-dropdown;
  top: $dropdown-top-offset;
  left: $dropdown-left-offset;
  min-width: 225px;
  padding: $spacer-small-x;
  border-radius: $radius-small;
  background-color: $color-surface-light;
}

.Dropdown__item {
  @include buttonReset;
  @include caption_large_medium;
  width: 100%;
  padding: $spacer-small-2x $spacer-large $spacer-small-2x $spacer-small-x;
  transition: background-color $transition-duration $transition-function;
  border-radius: $radius-small;
  background-color: transparent;

  &:hover {
    background-color: $color-background-light-50;
  }
}

.Dropdown__item_selected {
  color: $color-primary;
}