@import '../../../../vars';

.TextBlock {
  position: relative;
  padding-left: 32px;
  & + & {
    margin-top: 24px;
  }
}

.TextBlock__icon {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 16px;
  height: 16px;
  color: $color-primary;
}

.TextBlock__title {
  @include caption_large_medium;
  margin-bottom: 8px;
}

.TextBlock__text {
  @include caption_large_regular;
}
