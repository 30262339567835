@import '../../../../../vars';

.CardButton {
  @include buttonReset;
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: left;
  width: 188px;
  min-width: 188px;
  height: 120px;
  padding: $spacer-small-x;
  border: 2px solid $color-tag-2;
  border-radius: $radius-medium;
  &:hover {
    border: 2px solid $color-text-secondary;
  }
  &:active {
    border: 2px solid $color-primary-pressed;
    .CardButton__icon {
      color: $color-primary-pressed;
    }
    .CardButton__price {
      color: $color-primary-pressed;
    }
  }
}

.CardButton__small {
  width: 136px;
  min-width: 136px;
  height: 86px;
}

.CardButton__title {
  @include caption_large_medium;
  overflow: hidden;
  max-width: 136px;
  margin-bottom: 2px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.CardButton__text {
  @include caption_medium_medium;
  margin-bottom: $spacer-small-2x;
  color: $color-text-secondary;
}
.CardButton__price {
  @include caption_large_bold;
  line-height: 20px;
}
.CardButton__icon {
  position: absolute;
  top: $spacer-small-x;
  right: $spacer-small-x;

  @include mobile {
    display: none;
  }
}

.CardButton.CardButton_active {
  border: 2px solid $color-primary;
  .CardButton__icon {
    color: $color-primary-pressed;
  }
  .CardButton__price {
    color: $color-primary-pressed;
  }
  &:hover {
    border: 2px solid $color-primary-pressed;
    .CardButton__icon {
      color: $color-primary-pressed;
    }
    .CardButton__price {
      color: $color-primary-pressed;
    }
  }
  &:active {
    border: 2px solid $color-primary-pressed;
    .CardButton__icon {
      color: $color-primary-pressed;
    }
    .CardButton__price {
      color: $color-primary-pressed;
    }
  }
}

.CardButton:disabled {
  cursor: default;
  color: $color-text-secondary;
  background-color: $color-tag-2;
  &:hover {
    border: 2px solid $color-tag-2;
    .CardButton__icon {
      color: $color-text-secondary;
    }
    .CardButton__price {
      color: $color-text-secondary;
    }
  }
  &:active {
    border: 2px solid $color-tag-2;
    .CardButton__icon {
      color: $color-text-secondary;
    }
    .CardButton__price {
      color: $color-text-secondary;
    }
  }
}
