@import '../../../../../vars';

.ButtonRadios {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: $spacer-medium;
}

.ButtonRadios__header {
  @include caption_large_regular;
  width: 100%;
  margin-bottom: $spacer-medium;
  color: $color-surface-dark;
}

.ButtonRadio {
  @include caption_large_medium;
  align-items: center;
  margin-right: $spacer-small-2x;
  margin-bottom: $spacer-small-2x;
  padding: $spacer-small-2x $spacer-small;
  cursor: pointer;
  transition: background-color $transition-duration $transition-function,
    box-shadow $transition-duration $transition-function;
  vertical-align: middle;
  white-space: nowrap;
  text-decoration: none;
  color: $color-text-on-accent;
  border-radius: $radius-small;
  outline: none;
  background-color: lighten($color-primary, 10);
  font-style: normal;
  appearance: none;

  &:hover {
    background-color: $color-primary-hover;
    box-shadow: 0 0 0 2px rgba($color-primary, 0.4);
  }

  input {
    display: none;
  }
}

.ButtonRadio_active {
  color: $color-text-on-accent;
  background-color: $color-primary;
  box-shadow: 0 0 0 2px $color-primary;

  &:hover {
    background-color: $color-primary-hover;
    box-shadow: 0 0 0 2px $color-primary-hover;
  }
}

.ButtonRadio_disabled {
  color: $color-text-secondary;
  background-color: $color-white;
  box-shadow: 0 0 0 1px $color-tag-2;

  &:hover {
    background-color: $color-white;
    box-shadow: 0 0 0 2px $color-tag-2;
  }

  &.ButtonRadio_active {
    box-shadow: 0 0 0 2px $color-secondary-pressed;
  }
}
