@import '../../vars';

.OrderPage {
  padding: $spacer-large $spacer-large $spacer-medium;
  border-radius: $radius-large;
  background-color: $color-white;

  @include mobile {
    padding: $spacer-small;
    border-radius: 0;
  }
}

.OrderPage_loading {
  padding: $spacer-large-x;
  text-align: center;
}

.OrderDetails {
  max-width: 542px;
}

.OrderDetails__fullWidth {
  grid-area: 1 / 1 / 2 / 3;
}

.OrderDetails__deliveryBlock {
  max-width: 542px;
  > *:last-child {
    margin-bottom: 8px;
  }
}

.OrderDetails__title {
  @include caption_large_bold;
  margin-bottom: $spacer-medium;

  @include mobile {
    @include caption_large_medium;
    margin-bottom: $spacer-small;

    &:not(:first-child) {
      margin-top: $spacer-medium;
    }
  }
}

.OrderDetails__description {
  @include caption_large_regular;
  margin-top: $spacer-small;
  margin-bottom: $spacer-medium;
  p {
  }
}

.OrderDetails__image {
  width: 100%;
  max-width: 609px;
  height: 304px;
  border-radius: $radius-xlarge;
}

.OrderDetails__inputs {
  display: flex;
}

.OrderDetails__input {
  width: 100%;
  margin-right: $spacer-small;
  margin-bottom: 16px;
  &:last-child {
    margin-right: 0;
  }
}

.OrderDetails__tel {
  max-width: 225px;
  margin-right: $spacer-small;
}

.OrderDetails__policy {
  @include caption_medium_regular;
  color: $color-text-secondary;
  border: none;
}

.OrderDetails__externalLink {
  color: $color-primary;
}

.OrderDetails__textAccent {
  @include caption_large_medium;
  margin-left: $spacer-small-3x;
}

.OrderDetails__nextButton {
  margin-top: $spacer-large-x;
  margin-bottom: $spacer-large-2x;
}

.OrderDetails__deliveryTypes {
  display: flex;
  flex-wrap: wrap;

  @include mobile {
    margin: 0 -4px;
  }
}

.OrderDetails__deliveryType {
  margin: 0 $spacer-medium $spacer-medium 0 !important;

  @include mobile {
    width: calc(50% - 8px) !important;
    min-width: revert !important;
    margin: 4px !important;
  }
}

.OrderDetails__mobileFullWidth {
  .OrderDetails__deliveryType {
    @include mobile {
      width: 100% !important;
      height: auto;
    }
  }
}

.OrderDetailsBlock {
  margin-bottom: $spacer-medium;
  padding: $spacer-large;
  border-radius: $radius-xlarge;
  background-color: $color-white;

  @include mobile {
    padding: $spacer-small;
    border-radius: 0;
  }
}

.OrderDetailsBlock__title {
  @include caption_large_bold;
  margin-bottom: $spacer-medium;

  @include mobile {
    margin-bottom: $spacer-small;
  }
}

.OrderDetailsBlock__list {
}

.OrderDetailsBlock__card {
  padding: 0;
}

.OrderDetails__card {
  padding: 0;
  border-radius: 0;
}

.OrderDetails__spacer {
  margin: $spacer-medium 0;
  border-top: 1px solid rgba($color-text-primary, 0.1);
}
