@import "../../../../vars";

.SoldCardBanner {
  position: relative;
  padding: $spacer-large-3x $spacer-large-2x;
  border-radius: 20px;
  background: $color-surface-radial;

  @include lte_tabletLand {
    border-radius: 0;
    //padding: $spacer-large-3x calc((100% - #{$bp-tablet-land-min-width}) / 2);
  }

  @include tabletLand {
    padding: $spacer-large-3x calc((100% - #{$bp-tablet-land-min-width}) / 2 + 64px);
  }

  @include mobile {
    padding: $spacer-large-3x $spacer-small;
  }
}

.SoldCardBanner__title {
  @include header_h1_bold;
  width: 548px;
  color: $color-text-on-accent;

  @include tablet {
    @include header_h3_bold;
    width: 374px;
  }

  @include mobile {
    @include header_h4_bold;
    width: 186px;
    font-weight: 700;
  }
}

.SoldCardBanner__description {
  @include caption_large_regular;
  width: 548px;
  margin-top: $spacer-small;
  color: $color-text-on-accent;

  @include tablet {
    width: 374px;
  }

  @include mobile {
    width: 236px;
  }
}

.SoldCardBanner__actionButton {
  margin-top: 28px;

  @include tablet {
    margin-top: 56px;
  }
}

.SoldCardBanner__decoration {
  position: absolute;
  top: -40px;
  right: 90px;
  transform: rotate(15deg);

  @include tabletLand {
    right: -50px;
  }

  @include tablet {
    top: 0;
    right: -100px;
    width: 457px;
    height: 457px;
    transform: rotate(30deg);
  }

  @include mobile {
    top: 10px;
    right: -100px;
    width: 213px;
    height: 213px;
  }
}

.SoldCardBanner__card {
  position: absolute;
  top: -5px;
  right: 200px;
  transform: rotate(7.2deg);

  @include tabletLand {
    right: 50px;
  }

  @include tablet {
    top: 25px;
    right: -20px;
  }

  @include mobile {
    top: 100px;
    right: -75px;
  }
}

.SoldCardBanner__price {
  position: absolute;
  top: 200px;
  right: 100px;
  transform: rotate(-15deg);

  @include tabletLand {
    right: 250px;
  }

  @include tablet {
    right: 180px;
  }
}