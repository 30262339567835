@import "./baseText";

.header_1_bold {
  @include header_h1_bold;
}

.header_2_medium {
  @include header_h2_medium;
}

.header_3_bold {
  @include header_h3_bold;
}

.header_3_regular {
  @include header_h3_regular;
}

.header_4_bold_caps {
  @include header_h4_bold_caps;
}

.header_4_medium {
  @include header_h4_medium;
}

.caption_l_bold {
  @include caption_large_bold;
}

.caption_l_medium {
  @include caption_large_medium;
}

.caption_l_regular {
  @include caption_large_regular;
}

.caption_m_bold {
  @include caption_medium_bold;
}

.caption_m_medium {
  @include caption_medium_medium;
}

.caption_m_regular {
  @include caption_medium_regular;
}

.caption_s_bold_caps {
  @include caption_small_bold_caps;
}

.caption_s_medium {
  @include caption_small_medium;
}

.caption_xs_bold {
  @include caption_xsmall_bold;
}

.caption_xs_regular {
  @include caption_xsmall_regular;
}

kbd {
  font-family: monospace;
}
