$bp-mobile-min-width: 320px;
$bp-mobile-max-width: 767px;

$bp-tablet-min-width: 768px;
$bp-tablet-max-width: 1023px;

$bp-tablet-land-min-width: 1024px;
$bp-tablet-land-max-width: 1365px;

$bp-desktop-min-with: 1366px;

:export {
  mediaWidthMobile: $bp-mobile-max-width;
  mediaWidthTablet: $bp-tablet-max-width;
  mediaWidthTabletLand: $bp-tablet-land-max-width;
}

@mixin mobile() {
  @media (max-width: $bp-mobile-max-width) {
    @content;
  }
}

@mixin tablet() {
  @media (min-width: $bp-tablet-min-width) and (max-width: $bp-tablet-max-width) {
    @content;
  }
}

@mixin lte_tablet() {
  @media (max-width: $bp-tablet-max-width) {
    @content;
  }
}

@mixin tabletLand() {
  @media (min-width: $bp-tablet-land-min-width) and (max-width: $bp-tablet-land-max-width) {
    @content;
  }
}

@mixin lte_tabletLand() {
  @media (max-width: $bp-tablet-land-max-width) {
    @content;
  }
}

$layout-width: $bp-desktop-min-with;
$layout-tabletLand-width: $bp-tablet-land-min-width;
$layout-tablet-width: $bp-tablet-min-width;
$layout-mobile-width: $bp-mobile-min-width;

@mixin applyDynamicWidth($min, $max, $offset) {
  width: calc(100% - #{$offset});
  min-width: $min - $offset;
  max-width: $max - $offset;
}

@mixin desktopWidth {
  width: $bp-desktop-min-with - 88*2;
}

@mixin tabletLandWidth {
  @include applyDynamicWidth($bp-tablet-land-min-width, $bp-tablet-land-max-width, 64px * 2);
}

@mixin tabletWidth {
  @include applyDynamicWidth($bp-tablet-min-width, $bp-tablet-max-width, 64px * 2);
}

@mixin mobileWidth {
  @include applyDynamicWidth($bp-mobile-min-width, $bp-mobile-max-width, 16px * 2);
}

@mixin adaptiveWidth {
  @include desktopWidth;

  @include tabletLand {
    @include tabletLandWidth;
  }

  @include tablet {
    @include tabletWidth;
  }

  @include mobile {
    @include mobileWidth;
  }
}