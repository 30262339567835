@import '../../../../vars';

.Switcher {
}

.Switcher__option {
  @include buttonReset;
  margin: 0;
  padding: $spacer-small-2x;
  transition: color 0.2s ease, opacity 0.2s ease;
  opacity: 0.6;
  color: $color-text-secondary;

  &:hover {
    opacity: 1;
  }
}

.Switcher__option_active {
  opacity: 1;
  color: $color-primary;
}