@import '../../../../vars';

.Checkbox {
  display: inline-flex;
  align-items: flex-start;
  cursor: pointer;
  user-select: none;

  &:hover {
    .Checkbox__icon {
      &::after {
        border-color: $color-text-secondary;
      }
    }
  }

  &:active {
    .Checkbox__icon {
      background-color: $color-tag-2;

      &::after {
        border-color: $color-text-secondary;
      }
    }
  }
}

.Checkbox__icon {
  @include svgIcon;
  position: relative;
  top: 4px;
  width: 16px;
  min-width: 16px;
  height: 16px;
  transition: background-color $transition-duration $transition-function;
  color: transparent;
  border-radius: $radius-small;
  background-color: $color-surface-light;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    transition: border-color $transition-duration $transition-function;
    border: 1px solid $color-tag-2;
    border-radius: inherit;
  }
}

.Checkbox__input {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
}

.Checkbox__label {
  @include caption_medium_regular;
  width: -moz-available;
  width: -webkit-fill-available;
  margin-left: $spacer-small-x;
}

.Checkbox_checked {
  .Checkbox__icon {
    color: $color-surface-light;
    background-color: $color-primary;

    &::after {
      border-color: transparent;
    }
  }

  &:hover {
    .Checkbox__icon {
      background-color: $color-primary-hover;

      &::after {
        border-color: transparent;
      }
    }
  }

  &:active {
    .Checkbox__icon {
      background-color: $color-primary-pressed;
    }
  }
}

.Checkbox_disabled {
  cursor: default;

  &,
  &:hover,
  &:active {
    .Checkbox__icon {
      background-color: $color-background-gray;

      &::after {
        border-color: $color-tag-2;
      }
    }
  }

  &.Checkbox_checked {
    .Checkbox__icon {
      color: $color-text-secondary;
      background-color: $color-tag-2;
    }
  }

  .Checkbox__label {
    color: $color-text-secondary;
  }
}
