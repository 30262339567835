@import '../../../../vars';
@import './CardLoadingMixins';

.CardLoadingBlock {
  @include mobile {
    row-gap: $spacer-small-x;
  }
}

.CardLoadingBlock__image {
  @include applyDefaultCardLoading;
  display: block;
  width: 184px;
  height: 251px;
  margin: 0 auto;
  grid-row: 1;
  grid-column: 1;

  @include mobile {
    width: 184px;
    height: 250px;
  }
}

.CardLoadingBlock__title {
  @include applyDefaultCardLoading;
  width: 90%;
  height: 24px;
}

.CardLoadingBlock__expansion {
  @include applyDefaultCardLoading;
  width: 180px;
  height: 16px;
}

.CardLoadingBlock__tags {
  display: flex;
  grid-row: 2;
  grid-column: 1;

  span {
    @include applyDefaultCardLoading;
    width: 80px;
    height: 20px;
    margin-right: 8px;
  }

  @include mobile {
    span {
      width: 30px;
    }
  }
}

.CardLoadingBlock__action {
  @include applyDefaultCardLoading;
  width: 100%;
  height: 40px;
}

.CardLoadingBlock_list {
  .CardLoadingBlock__image {
    grid-row: 1 / span 3;
    grid-column: 1;

    @include mobile {
      width: 65px;
      height: 88px;
      grid-row: 1 / span 2;
    }
  }

  .CardLoadingBlock__expansion {
    grid-row: 1;
    grid-column: 2;

    @include mobile {
      grid-row: 1;
    }
  }

  .CardLoadingBlock__title {
    margin-top: 36px;
    grid-row: 1;
    grid-column: 2;

    @include mobile {
      margin-top: -20px;
      grid-row: 2;
    }
  }

  .CardLoadingBlock__action {
    height: 50px;
    grid-row: 2 / span 2;
    grid-column: 2;

    @include mobile {
      grid-row: 3;
      grid-column: 1 / span 2;
    }
  }

  .CardLoadingBlock__tags {
    margin-top: 20px;
    grid-row: 4;
    grid-column: 1;

    @include mobile {
      display: none;
    }
  }
}
