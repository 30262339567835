@import '../../../../vars';
@import './_NumberSliderMixins';

.NumberSlider {
  @include caption_large_bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $color-text-primary;
  border: 2px solid $color-text-primary;
  border-radius: $radius-medium;
  background-color: $color-surface-light;
}

.NumberSlider__decrement,
.NumberSlider__increment {
  @include buttonReset;
  display: inline-flex;
  cursor: pointer;
  vertical-align: middle;
  border: none;
  border-radius: $radius-medium;
  background-color: transparent;
  appearance: none;

  &:disabled {
    cursor: default;
    opacity: 0.2;

    &:hover,
    &:active {
      background-color: transparent;
    }
  }
}

.NumberSlider__value {
  text-align: center;
  white-space: nowrap;
}

.NumberSlider_size_small {
  @include NumberSliderSizeMixin($spacer-small-3x, $spacer-small-2x, 8px, 0);
}

.NumberSlider_size_medium {
  @include NumberSliderSizeMixin($spacer-small-3x, $spacer-small-x, 8px, 0);
}

.NumberSlider_size_large {
  @include NumberSliderSizeMixin($spacer-small-2x, $spacer-small-2x, 16px, 4px);
}

.NumberSlider_variant_primary {
  color: $color-primary;
  border-color: $color-primary;

  .NumberSlider__increment, .NumberSlider__decrement {
    &:hover {
      background-color: rgba($color-primary, $opacity-10);
    }

    &:active {
      background-color: rgba($color-primary, $opacity-20);
    }
  }
}

.NumberSlider_variant_secondary {
  color: $color-secondary;
  border-color: $color-secondary;

  .NumberSlider__increment, .NumberSlider__decrement {
    &:hover {
      background-color: rgba($color-secondary, $opacity-10);
    }

    &:active {
      background-color: rgba($color-secondary, $opacity-20);
    }
  }
}