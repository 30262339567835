@import './tokens';
@import './text';

$transition-duration: 0.2s;
$transition-function: ease;

$layout-width: 1190px;
$layout-width-tabletLand: 884px;
$layout-width-tablet: calc(100% - 72px);
$layout-width-mobile: calc(100% - 32px);

$header-height-desktop: 72px;
$header-height-tablet: 64px;
$header-height-mobile: 56px;

@function px($v) {
  @return $v / 16 * 1em;
}
@function rpx($v) {
  @return $v / 16 * 1rem;
}
@function invert($v) {
  @return calc(-1 * #{$v});
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin buttonReset {
  overflow: hidden;
  margin: 0;
  padding: 0;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
  color: inherit;
  border: none;
  outline: none;
  background: none;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

@mixin inputReset {
  padding: 0;
  text-align: left;
  opacity: 1;
  color: inherit;
  border: none;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  //safari disabled input bug
  -webkit-text-fill-color: $color-text-primary;
}

// Миксин, который позволяет использовать как нативный :focus-visible, так и с полифиллом.
// К сожалению postcss-focus-visible, который это сам делает, не работает в create-react-app
@mixin focusVisible {
  &:global(.focus-visible) {
    @content;
  }

  // Закомментировано из-за ошибки в Safari
  //&:focus-visible {
  //  @content;
  //}
}

@mixin focusVisibleAndHover {
  &:hover {
    @content;
  }
  @include focusVisible {
   @content;
  }
}

@mixin applyStandartOutline {
  outline: none;

  @include focusVisible {
    box-shadow: 0 0 0 2px $color-surface-dark;
  }
}

@mixin applyInvertedOutline {
  outline: none;

  @include focusVisible {
    box-shadow: 0 0 0 2px $color-surface-light;
  }
}

@mixin svgIcon {
  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}
