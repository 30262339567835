@import '../../../../vars';

.CardPreviewModal {
  display: flex;
  overflow: visible;
  align-items: center;
  background: transparent;

  :global {
    .Popup__closeSvg {
      right: 0;
      left: $spacer-small;
      width: 18px;
      height: 18px;
      color: $color-white;
    }

    .Popup__closeButton {
      right: 0;
    }
  }

  @include mobile {
    padding: $spacer-small;
  }
}

.CardPreviewModal__overlay {
  overflow-x: hidden;

  @include mobile {
    align-items: center;
    padding-top: $header-height-mobile;
  }
}

.CardPreviewModal__content {
  transition: transform 0.5s ease;
}

.CardPreviewModal__closeButton {
  right: 0;
}

.CardPreviewModal__rotateButton {
  @include buttonReset;
  @include applyStandartOutline;
  position: absolute;
  right: 0;
  bottom: 32px;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  color: $color-text-on-accent;

  @include focusVisibleAndHover {
    background-color: transparent;
  }

  @include mobile {
    position: fixed;
    bottom: unquote('calc(16px + env(safe-area-inset-bottom))');
    left: 50%;
    width: 32px;
    height: 32px;
    margin-left: -16px;
    border-radius: 50%;
    background-color: rgba($color-surface-dark, 0.4);
  }
}

.CardPreviewModal__closeButton, .CardPreviewModal__rotateButton {
  @include focusVisibleAndHover {
    background-color: transparent;
  }
}
