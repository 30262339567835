@import "../../../../vars";

.IndexPageBlock {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  padding: $spacer-large-2x 0;

  @include mobile {
    padding: $spacer-large 0;
  }
}

.IndexPageBlock__header {
  @include header_h2_medium;
  flex: 1;

  @include tablet {
    @include header_h3_medium;
  }

  @include mobile {
    @include header_h4_medium;
  }
}

.IndexPageBlock__actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 355px;

  @include tablet {
    margin-top: 7px;
  }

  @include mobile {
    width: auto;
  }
}

.IndexPageBlock__content {
  width: 100%;
  margin-top: $spacer-large;

  @include mobile {
    margin-top: $spacer-medium;
  }
}

.IndexPageBlock__link {
  @include caption_large_bold;
  @include applyStandartOutline;
  padding-bottom: $spacer-small-2x;
  transition: color $transition-duration $transition-function;
  color: $color-text-primary;
  border-bottom: 1px solid $color-text-primary;
  outline: 0;

  @include mobile {
    padding-bottom: 0;
    border-bottom: none;
  }

  @include focusVisibleAndHover {
    color: $color-primary;
  }
}

.IndexPageBlock__button {
  @include buttonReset;
  @include applyStandartOutline;
  width: 32px;
  height: 32px;
  transition: color $transition-duration $transition-function, background-color $transition-duration $transition-function;
  border-radius: 50%;

  &:first-of-type {
    margin-left: $spacer-large-2x;
  }

  & + & {
    margin-left: $spacer-small-x;
  }

  &:active {
    color: $color-primary-pressed;
  }

  @include focusVisibleAndHover {
    color: $color-primary-hover;
    background-color: rgba($color-primary, $opacity-40);
  }
}

.IndexPageBlock_invert {
  color: $color-text-on-accent;

  .IndexPageBlock__button {
    color: $color-text-on-accent;

    &:active {
      color: $color-text-on-accent;
    }

    @include focusVisibleAndHover {
      color: $color-text-on-accent;
      background-color: rgba($color-text-on-accent, $opacity-40);
    }
  }

  .IndexPageBlock__link {
    color: white;
    border-bottom-color: white;

    @include focusVisibleAndHover {
      color: $color-primary;
      border-bottom-color: $color-primary;
    }
  }
}