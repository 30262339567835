@import "../../../../vars";
@import './CardLoadingMixins';

.ExpansionCard {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 360px;
  padding: 40px 30px;
  border: none;
  border-radius: 20px;
  clip-path: border-box;
}

.ExpansionCard_loading {
  @include applyDefaultCardLoading;
  border-radius: 20px;
}

.ExpansionCard__image {
  position: absolute;
  z-index: $z-index-base;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ExpansionCard__title {
  @include header_h4_medium;
  position: absolute;
  z-index: $z-index-above;
  margin-right: 24px;
  color: $color-text-on-accent;
}

.ExpansionCard__action {
  @include caption_large_bold;
  position: absolute;
  z-index: $z-index-above;
  bottom: 38px;
  display: inline-block;
  width: max-content;
  margin-top: auto;
  padding-bottom: 8px;
  color: $color-text-on-accent;
  border-bottom: 1px solid $color-surface-light;
}

.ExpansionCard__decoration {
  position: absolute;
  z-index: $z-index-above;
  transition: transform 0.3s ease, top 0.3s ease, left 0.3s ease;
}

.ExpansionCard_decoration_1 {
  .ExpansionCard__decoration {
    top: -320px;
    left: -250px;
    transform: rotate(15deg);
  }

  &:hover, &:focus {
    .ExpansionCard__decoration {
      top: -300px;
      left: -230px;
    }
  }
}

.ExpansionCard_decoration_2 {
  .ExpansionCard__decoration {
    top: -180px;
    left: -250px;
    transform: rotate(-15deg);
  }

  &:hover, &:focus {
    .ExpansionCard__decoration {
      top: -150px;
      left: -200px;
    }
  }
}

.ExpansionCard_decoration_3 {
  .ExpansionCard__decoration {
    top: -280px;
    left: -70px;
    transform: rotate(15deg);
  }

  &:hover, &:focus {
    .ExpansionCard__decoration {
      top: -220px;
      left: -80px;
    }
  }
}

.ExpansionCard_decoration_4 {
  .ExpansionCard__decoration {
    top: 180px;
    left: 50px;
    transform: rotate(-15deg);
  }

  &:hover, &:focus {
    .ExpansionCard__decoration {
      top: 150px;
      left: -10px;
    }
  }
}

.ExpansionCard_decoration_5 {
  .ExpansionCard__decoration {
    top: 0;
    left: 0;
    transform: rotate(15deg);
  }

  &:hover, &:focus {
    .ExpansionCard__decoration {
      top: 10px;
      left: -50px;
    }
  }
}