@import '../../../../vars';
@import './InputMixins';

.Textarea {
}

.Textarea__control {
  @include InputBaseStyles;
  min-height: 80px;
  max-height: 500px;
  resize: vertical;
}

.Textarea_invalid {
  .Textarea__control {
    @include InputInvalidStyles;
  }
}