@import '../../../../vars';

.ErrorMessage {
  padding: $spacer-large;
  text-align: center;
  border-radius: $radius-xlarge;
  background-color: white;
  grid-column: 1/8;
}

.ErrorMessage__text {
  @include text_accent;
  @include header_h3_bold;
}

.ErrorMessage__description {
  @include text_body;
  @include caption_large_medium;
  margin-top: $spacer-small;
}

.ErrorMessage__children {
  margin-top: $spacer-small;
}

.ErrorMessage__actionButton {
  margin-top: $spacer-large;

  & + & {
    margin-left: $spacer-small-x;
  }
}

