@import '../../_vars';
@import './IndexPageMixins';

$scroller-card-width: 218px;
$scroller-gap: 24px;

.IndexPage__newCards {
  @include ScrollerGrid(15, 288px);
}

.IndexPage__setsBlock {
  @include adaptiveWidth;
  margin: 0 auto;
}

.IndexPage__sets {
  @include ScrollerGrid(5, $scroller-card-width);
}

.IndexPage__expansions {
  @include ScrollerGrid(5, $scroller-card-width);
}

.IndexPage__soldBanner {
  margin-top: 34px;

  @include lte_tabletLand {
    overflow: hidden;
    margin-top: 0;
    background-color: $color-background-light;
  }

  @include tabletLand {
    padding-top: 74px;
    padding-bottom: 128px;
  }

  @include tablet {
    padding-top: 0;
    padding-bottom: 48px;
  }

  @include mobile {
    padding-bottom: 48px;
  }
}

.IndexPage__actionButton {
  padding: $spacer-small $spacer-medium;

  @include mobile {
    padding: $spacer-small-2x $spacer-medium;
  }
}

.IndexPage__gradientBackground {
  background: $color-surface-radial;
}