@import 'src/vars';

.FilterCard {
  padding: $spacer-small $spacer-small-x;
  border-radius: $radius-medium;
  background-color: $color-surface-light;

  @include lte_tablet {
    position: fixed;
    z-index: $z-index-dropdown;
    overflow-y: auto;
    border-radius: 0;
  }

  @include tablet {
    top: 0;
    left: -100%;
    width: 350px;
    max-height: 100%;
    padding: $spacer-medium;
    padding-top: 88px;
    border-radius: 0;
    box-shadow: 2px 0 10px 0 rgba(black, 0.05);
  }

  @include mobile {
    top: 150vh;
    width: 100vw;
    height: 100vh;
    padding-top: 80px;
    padding-bottom: 20vh;
    opacity: 0;
  }
}

.FilterCard__header {
  @include header_h4_medium;
}

.FilterCard__block {
  margin: $spacer-small 0;
}

.FilterCard__subheader {
  @include caption_large_medium;
  margin-bottom: $spacer-small-2x;
}

.FilterCard__clear {
  margin-top: $spacer-small;
  margin-bottom: 2px;
  padding: $spacer-small-3x $spacer-small-x;
  padding-left: $spacer-small-2x;
}

.FilterCard__footer {
  position: fixed;
  z-index: $z-index-fixed;
  bottom: -200px;
  left: 0;
  display: flex;
  width: 100%;
  padding: $spacer-small-2x $spacer-small unquote('calc(#{$spacer-small-2x} + env(safe-area-inset-bottom))');
  transition: bottom $transition-duration $transition-function;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  background: $color-surface-radial;
}

.FilterCard__footerLabel {
  @include caption_small_medium;
  flex-grow: 1;
  color: $color-text-on-accent;
}

.FilterCard__footerCounter {
  @include caption_large_medium;
  color: $color-primary;
}

.FilterCard__footerAction {
  width: 164px;
}


.FilterCard_visible {
  @include tablet {
    animation: show-filters-tablet $transition-duration $transition-function;
    animation-fill-mode: forwards;
  }

  @include mobile {
    animation: show-filters-mobile $transition-duration $transition-function;
    opacity: 1;
    animation-fill-mode: forwards;

    .FilterCard__footer {
      bottom: 0;
    }
  }
}

.FilterCard_hide {
  @include tablet {
    animation: hide-filters-tablet $transition-duration $transition-function;
    animation-fill-mode: forwards;
  }

  @include mobile {
    animation: hide-filters-mobile $transition-duration $transition-function;
    animation-fill-mode: forwards;
  }
}

@keyframes show-filters-tablet {
  0% {
    left: -100%;
  }
  100% {
    left: 0;
  }
}

@keyframes hide-filters-tablet {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}

@keyframes show-filters-mobile {
  0% {
    top: 100vh;
    opacity: 0;
  }
  1% {
    opacity: 1;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}

@keyframes hide-filters-mobile {
  0% {
    top: 0;
    opacity: 1;
  }
  99% {
    opacity: 1;
  }
  100% {
    top: 100vh;
    opacity: 0;
  }
}
