@import '../../../../vars';
@import './ButtonMixins';

.Button {
  @include buttonReset;
  @include caption_large_bold;
  @include applyStandartOutline;
  @include buttonPrimaryMixin;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color $transition-duration $transition-function;
  vertical-align: middle;
  text-decoration: none;
  color: $color-text-on-accent;
  border: none;
  border-radius: $radius-medium;
  background-color: $color-primary;
  appearance: none;

  &:disabled {
    cursor: default;
    color: $color-text-secondary;
    background-color: $color-tag-2;

    &:hover,
    &:active {
      background-color: $color-tag-2;
    }
  }
}

.Button_variant_primary {
  @include buttonPrimaryMixin;
}

.Button_variant_secondary {
  @include buttonSecondaryMixin;
}

.Button_variant_iconButton {
  @include iconButtonMixin;
}

.Button_size_medium {
  padding: $spacer-small $spacer-large;
}

.Button_size_small {
  padding: $spacer-small-2x;
}

.Button__icon_left,
.Button__icon_right {
  line-height: $font_size_16;
}

.Button__icon_left {
  margin-right: $spacer-small;
}

.Button__icon_right {
  margin-left: $spacer-small;
}

.Button_fullWidth {
  width: 100%;

  .Button_size_medium {
    padding: $spacer-small;
  }

  .Button__size_small {
    padding: $spacer-small-2x;
  }
}

.Button__content {

}

.Button__spinner {
  --spinner-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Button_withSpinner {
  .Button__content {
    user-select: none;
    opacity: 0;
  }
}
