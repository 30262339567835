@import '../../../../vars';

.CardBasePopup {
  max-width: 415px;
}

.CardBasePopup__title {
  @include header_h4_medium;
  margin-bottom: $spacer-small;
}

.CardBasePopup__description {
  @include caption_large_regular;
  margin-bottom: $spacer-medium;
}

.CardBasePopup__inputs {
  > * {
    margin-bottom: $spacer-small;
  }
}
