@import '../../../../vars';
@import './CardMixins';

.CardImage {
  position: relative;
  overflow: hidden;

  /* Safari overflow:hidden + border-radius bug */
  transform: translateZ(0);
}

.CardImage__image {
  display: block;
  width: 100%;
  border-radius: $radius-xlarge;
}

.CardImage__overlay {
  position: absolute;
  z-index: $z-index-behind;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background: $color-card-light;
  mix-blend-mode: difference;
}

.CardImage__preview {
  position: absolute;
  z-index: $z-index-above;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: opacity $transition-duration $transition-function;
  opacity: 0;
  color: rgba($color-text-on-accent, $opacity-60);
  background-color: rgba(black, $opacity-20);
}

.CardImage {
  &:hover {
    .CardImage__preview {
      opacity: 1;
    }
  }
}

.CardImage_size_autoSize {
  width: 100%;
  padding: 10px;
  border-radius: 16px;
}

.CardImage_size_xlarge {
  @include CardImageSize(384px, 523px, 18px, $radius-xxlarge);
}

.CardImage_size_large {
  @include CardImageSize(271px, 370px, 14px, $radius-xlarge);
}

.CardImage_size_medium {
  @include CardImageSize(184px, 251px, 8px, $radius-large);
}

.CardImage_size_small {
  @include CardImageSize(156px, 211px, 8px, $radius-large);
}

.CardImage_size_banner {
  @include CardImageSize(133px, 182px, 8px, 10px);
}

.CardImage_size_xsmall {
  @include CardImageSize(95px, 130px, 4px, $radius-small);
}

.CardImage_size_xxsmall {
  @include CardImageSize(65px, 88px, 3px, 5px);
}

.CardImage_foil {
  .CardImage__overlay {
    z-index: $z-index-above;
    background: $color-card-foil;
    backdrop-filter: none;
  }
}
