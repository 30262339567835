@import "../../../../vars";

.SetCard {
  @include buttonReset;
  position: relative;
  display: inline-flex;
  overflow: hidden;
  flex-direction: column;
  width: 100%;
  height: 360px;
  padding: 40px 28px;
  transition: background 0.2s ease;
  color: $color-text-on-accent;
  border: 1px solid rgba($color-surface-light, 0.15);
  border-radius: 20px;
  background: linear-gradient(14.19deg, rgba(218, 216, 214, 0.1) 28.99%, rgba(10, 7, 3, 0) 92.97%);
  clip-path: border-box;

  &::before {
    position: absolute;
    top: -100px;
    left: -180px;
    width: 0;
    height: 0;
    content: '';
    transition: width 0.3s linear, height 0.3s linear;
    border-radius: 50%;
    background: rgba($color-primary, 0.8);
  }

  &:hover {
    color: $color-text-on-accent;

    &::before {
      width: 500px;
      height: 500px;
    }

    .SetCard__image {
      transform: scale(1.1) translateX(-4%);
    }
  }

  @include focusVisible {
    &::before {
      width: 500px;
      height: 500px;
    }

    .SetCard__image {
      transform: scale(1.1) translateX(-4%);
    }
  }
}

.SetCard__title {
  @include header_h4_bold_caps;
  z-index: $z-index-above;
}

.SetCard__image {
  position: absolute;
  right: 0;
  bottom: 0;
  transition: transform $transition-duration linear;
}

.SetCard__action {
  @include caption_large_bold;
  z-index: $z-index-above;
  display: inline-block;
  width: max-content;
  margin-top: auto;
  padding-bottom: 8px;
  border-bottom: 1px solid $color-surface-light;
}