@import '../../../../vars';

$spinner-rotate-animation: spinner-rotate 1.4s linear infinite;
$spinner-rotate-dash: spinner-dash 1.4s ease-in-out infinite;
$spinner-rotate-dash-done: spinner-dash-done 0.9s ease-in-out forwards;
$spinner-loading-svg-transition: all 0.2s ease-in-out;
$spinner-overlay-transition: all 0.6s ease-in-out;

.Spinner {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;

  * {
    color: var(--spinner-color, $color-primary);
  }
}

.Spinner__overlay {
  z-index: $z-index-above;
  transition: $spinner-overlay-transition;
  stroke: currentColor;
}

.Spinner.Spinner_without-done-fill-animation.Spinner_done
.Spinner__checkmark {
  animation: none;
}

.Spinner__checkmark {
  position: absolute;
  z-index: $z-index-base;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  margin: auto;
  border-radius: 50%;
  stroke-width: 2;
  stroke: currentColor;
  stroke-miterlimit: 10;
}

.Spinner__checkmark-check {
  transform-origin: 50% 50%;
  animation: checkmark-stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
}

.Spinner_done {
  .Spinner__checkmark {
    animation: checkmark-fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
    box-shadow: inset 0 0 0 currentColor;
  }

  .Spinner__overlay {
    animation: $spinner-rotate-dash-done;
  }
}

.Spinner_loading {
  & > .Spinner__checkmark {
    transition: $spinner-loading-svg-transition;
    transform-origin: center center;
    animation: $spinner-rotate-animation;
  }

  .Spinner__overlay {
    animation: $spinner-rotate-dash;
    stroke-linecap: round;
    stroke-dasharray: 80, 200;
    stroke-dashoffset: 0;
  }
}

.Spinner_centered {
  margin: 0 auto;
}

@keyframes checkmark-fill {
  100% {
    box-shadow: var(
                    --checkmark-fill-box-shadow,
                    inset 0 0 0 30px var(--checkmark-fill-color, #fff)
    );
  }
}

@keyframes checkmark-stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes spinner-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -15px;
  }

  100% {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -125px;
  }
}

@keyframes spinner-dash-done {
  0% {
    stroke-dasharray: 0, 200;
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dasharray: 200, 200;
    stroke-dashoffset: 0;
  }
}

@keyframes spinner-rotate {
  100% {
    transform: rotate(360deg);
  }
}
