@import '../../vars';

.PoliciesPage__header {
  @include header_h2_medium;
  margin: 0 0 24px;

  @include lte_tabletLand {
    @include header_h3_medium;
  }

  @include mobile {
    @include header_h4_bold;
  }
}

.PoliciesPage__link {
  @include caption_large_regular;
  color: $color-primary;
}

.PoliciesPage__policy {
  @include caption_large_medium;
}
