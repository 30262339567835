@import '../../../../vars';

.InputHelpBlock {
  @include caption_xsmall_regular;
  width: 100%;
  padding-left: $spacer-small-x;
}

.InputHelpBlock_status_info {
  opacity: $opacity-60;
  color: $color-text-primary;
}

.InputHelpBlock_status_error {
  color: $color-error;
}