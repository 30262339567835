@import '../../../../vars';

.SetsPopup {
  position: absolute;
  z-index: $z-index-popup;
  top: 100%;
  width: 100%;

  @include tablet {
    top: $header-height-tablet;
  }

  @include mobile {
    top: $header-height-mobile + 16px;
  }
}

.SetsPopup__header {
  @include header_h4_medium;
  width: 100%;
  margin-bottom: 20px;
}

.SetsPopup__content {
  @include adaptiveWidth;
  @include shadow_onlight_cold;
  display: flex;
  max-height: 640px;
  margin: 0 auto;
  border-radius: 0 0 $radius-small $radius-small;
  background-color: $color-surface-light;

  @include mobile {
    width: calc(100% + 32px);
    max-width: calc(100% + 32px);
    height: calc(100vh - 56px);
    max-height: 100vh;
    margin: 0 -$spacer-small;
    padding: $spacer-small $spacer-small 0 $spacer-small;
  }
}

.SetsPopup__setList {
  display: flex;
  flex-direction: column;
  padding: $spacer-medium 0 $spacer-medium 100px;
  border-bottom-left-radius: $spacer-small-3x;
  background-color: $color-background-light;

  @include tabletLand {
    width: 260px;
    padding-left: 64px;
  }

  @include tablet {
    width: 232px;
    padding-left: $spacer-large;
  }

  @include mobile {
    width: 100%;
    padding: 0;
    background-color: $color-surface-light;
  }
}

.SetsPopup__set {
  @include buttonReset;
  @include caption_large_medium;
  @include applyStandartOutline;
  width: 240px;
  max-width: 100%;
  margin-bottom: $spacer-small-2x;
  padding: $spacer-small-2x $spacer-small-x;
  cursor: pointer;
  text-align: left;
  border: none;
  border-radius: $radius-small 0 0 $radius-small;

  @include focusVisibleAndHover {
    color: $color-primary-hover;
  }

  @include mobile {
    @include caption_large_regular;
    padding: $spacer-small-x 0;
  }
}

.SetsPopup__set_selected {
  color: $color-primary;
  background-color: white;
}

.SetsPopup__lists {
  position: relative;
  display: flex;
  overflow-y: scroll;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 24px 34px;

  @include mobile {
    display: block;
    padding: 0;

    .SetsPopup__header {
      margin-top: $spacer-small;
    }
  }
}

.SetsPopup__groupsList {
  display: flex;
  flex-direction: column;
  width: 764px;
  max-width: 100%;
}

.SetsPopup__setsGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 48px;
  grid-row-gap: $spacer-small-3x;

  @include lte_tablet {
    display: flex;
    flex-direction: column;
  }
}

.SetsPopup__group {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 48px;
  margin-bottom: $spacer-medium;

  @include tabletLand {
    margin-right: $spacer-medium;
  }

  @include tablet {
    width: 360px;
  }

  @include mobile {
    width: 100%;
    margin-right: 0;
  }
}

.SetsPopup__groupTitle {
  @include caption_xsmall_regular;
  position: sticky;
  z-index: $z-index-above;
  top: -24px;
  margin-bottom: $spacer-small-2x;
  padding: $spacer-small-3x $spacer-small-x;
  text-transform: capitalize;
  border-bottom: 1px solid $color-primary;
  background-color: white;

  @include mobile {
    @include caption_xsmall_regular;
    top: -5px;
    padding-bottom: $spacer-small-3x;
    padding-left: 0;
  }
}

.SetsPopup__expansion {
  @include buttonReset;
  @include caption_large_regular;
  @include applyStandartOutline;
  display: flex;
  align-items: center;
  width: 100%;
  padding: $spacer-small-x;
  cursor: pointer;
  transition: background-color $transition-duration $transition-function;
  text-align: left;
  border: none;
  border-radius: $radius-small;

  @include focusVisibleAndHover {
    @include caption_large_medium;
    background-color: $color-background-light-50;

    .SetsPopup__expansionIcon {
      opacity: 1;
    }
  }
}

.SetsPopup__expansionIcon {
  width: 24px;
  height: auto;
  opacity: 0.4;
}

.SetsPopup__placeholderIcon {
  width: 24px;
  height: 1px;
}

.SetsPopup__expansionTitle {
  margin-left: $spacer-small-2x;
  color: $color-text-primary;
}

.SetsPopup__allCards {
  @include caption_large_medium;
  position: absolute;
  top: $spacer-small;
  right: $spacer-medium;
  padding: $spacer-small-2x $spacer-small;

  @include mobile {
    @include caption_large_bold;
    position: static;
    margin-top: auto;
    margin-bottom: $spacer-medium;
  }
}
