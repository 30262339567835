@import '../../../../vars';
@import './Input.module';
@import './InputMixins';

.SearchInput__input {
  &::placeholder {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.SearchInput__clear {
  @include buttonReset;
  height: 24px;
  margin: 0;
  line-height: 22px;

  &:disabled {
    cursor: default;
  }
}