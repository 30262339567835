@import '../../../../vars';

.NothingFoundScreen {
  width: 100%;
  padding: 80px;
  text-align: center;
  border-radius: $radius-xlarge;
  background-color: $color-surface-light;

  @include mobile {
    width: calc(100% + 32px);
    margin-left: -16px;
    padding: 96px 36px;
    border-radius: 0;
  }
}

.NothingFoundScreen__title {
  @include header_h4_bold;
  margin-top: $spacer-medium;

  @include mobile {
    @include header_h4_medium;
  }
}

.NothingFoundScreen__description {
  @include caption_large_regular;
  margin-top: $spacer-small;
}

.NothingFoundScreen__link {
  color: $color-primary;

  @include focusVisibleAndHover {
    color: $color-primary-hover;
  }
}