@import '../../../../../vars';
@import '../CardMixins';

.CardBlock {
  position: relative;
  display: grid;
  align-items: flex-start;
  flex-direction: row;
  padding: $spacer-medium;
  border-radius: $radius-xlarge;
  background-color: $color-surface-light;
  grid-template-columns: min-content 1fr;
  grid-column-gap: $spacer-large;

  @include lte_tablet {
    margin: 0;
    padding: $spacer-small;
    grid-column-gap: $spacer-small;
  }
}

.CardBlock__isFullWidth {
  border-radius: 0;
}

.CardBlock__cardContainer {
  grid-column: 1;
  grid-row: 1 / span 4;
}

.CardBlock__image {
  margin: 0 auto;
}

.CardBlock__innerTagsList {
  min-height: $spacer-medium;
  margin-top: $spacer-small;

  @include lte_tabletLand {
    display: none;
  }
}

.CardBlock__outerTagsList {
  display: flex;

  @include lte_tabletLand {
    grid-column: 2;
    grid-row: 3;
  }
}

.CardBlock__expansion {
  @include buttonReset;
  @include caption_xsmall_bold;
  display: inline-flex;
  align-items: center;
  margin-top: $spacer-small-2x;
  text-transform: uppercase;
  color: rgba($color-surface-dark, 0.5);
  grid-column: 2;
  grid-row: 1;
}

.CardBlock__expansionIcon {
  width: auto;
  height: 12px;
  margin-left: $spacer-small-2x;
  opacity: 0.4;
}

.CardBlock__titleBlock {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  @include lte_tabletLand {
    flex-direction: column;
  }

  @include mobile {
    flex-wrap: wrap;
  }
}

.CardBlock__title {
  @include buttonReset;
  @include header_h4_medium;

  @include applyStandartOutline;
  display: -webkit-box;
  margin-top: $spacer-small-2x;
  margin-bottom: $spacer-medium;
  color: $color-surface-dark;
  grid-row: 2;
  grid-column: 2;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;

  @include mobile {
    margin-bottom: $spacer-small-2x;
  }

  @include focusVisibleAndHover {
    color: $color-primary;
  }
}

.CardBlock__actions {
  width: 100%;
  margin-top: auto;
  grid-row: 3;
  grid-column: 2;

  @include lte_tabletLand {
    margin-top: $spacer-medium;
    grid-row: 5;
    grid-column: 1 / span 2;
  }
}

.CardBlock__comingSoon {
  position: absolute;
  z-index: $z-index-above;

  @include tabletLand {
    right: 0;
  }

  @include tablet {
    right: 0;
  }
  @include mobile {
    width: 60px;
    height: 60px;
  }
}

.CardBlock__notInSale {
  position: absolute;
  z-index: $z-index-above;

  @include tabletLand {
    right: 0;
  }

  @include tablet {
    right: 0;
  }
}

.CardBlock__isCompact {
  height: 472px;
  grid-template-columns: 1fr;

  .CardBlock__cardContainer {
    grid-column: 1;
    grid-row: 1;
  }

  .CardBlock__innerTagsList {
    grid-column: 1;
    grid-row: 2;

    @include tabletLand {
      display: block;
    }

    @include tablet {
      display: block;
    }
  }

  .CardBlock__expansion {
    grid-column: 1;
    grid-row: 2;
  }

  .CardBlock__title {
    margin-top: 0;
    margin-bottom: $spacer-small;
    grid-column: 1;
    grid-row: 4;
  }

  .CardBlock__actions {
    margin-top: auto;
    grid-column: 1;
    grid-row: 5;
  }

  .CardBlock__comingSoon {
    top: 0;
    left: 0;
  }

  .CardBlock__notInSale {
    top: 0;
    left: 0;
  }

  .CardBlock__outerTagsList {
    display: none;
  }

  @include tablet {
    margin: 0;
    padding: $spacer-small;
  }

  @include mobile {
    height: auto;

    .CardBlock__innerTagsList {
      display: flex;
    }
  }
}

.CardBlock_cartCard {
  .CardBlock__outerTagsList {
    grid-column: 3;
  }
}
