@import '../../../../vars';

$header-logo-width: 166px;
$header-navigation-width: 231px;
$header-cart-width: 113px;
$header-normal-search-width: calc(100% - #{$header-logo-width + $header-navigation-width + $header-cart-width}); //397
$header-normal-search-offset: $header-logo-width + $header-navigation-width;
$header-focused-search-width: calc(100% - #{$header-logo-width});
$header-focused-search-offset: $header-logo-width;

.Header {
  position: fixed;
  z-index: $z-index-header;
  top: 0;
  width: 100%;
  height: $header-height-desktop;
  transform: translateZ(0);
  background: $color-surface-radial;

  @include tablet {
    height: $header-height-tablet;
  }

  @include mobile {
    z-index: $z-index-header-mobile;
    height: $header-height-mobile;
  }
}

.Header__content {
  @include adaptiveWidth;
  position: relative;
  z-index: $z-index-above;
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: $spacer-small 0;

  @include lte_tabletLand {
    margin-right: auto;
    margin-left: auto;
  }
}

.Header__logo {
  @include mobile {
    width: auto;
    height: 18px;
  }
}

.Header__navigation {
  margin-left: 48px;

  @include tablet {
    flex-shrink: 0;
    margin-left: $spacer-large;
  }

  @include mobile {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
}

.Header__link {
  @include buttonReset;
  @include caption_medium_medium;
  display: inline-flex;
  align-items: center;
  transition: color $transition-duration $transition-function;
  text-decoration: none;
  color: $color-text-on-accent;
  line-height: $font_size_32;

  & + & {
    margin-left: 48px;

    @include tabletLand {
      margin-left: $spacer-large-x;
    }

    @include tablet {
      margin-left: $spacer-medium;
    }

    @include mobile {
      margin-left: $spacer-small;
    }
  }

  &:hover, &:focus {
    color: $color-primary-on-dark;

    @include mobile {
      color: $color-primary;
    }
  }
}

.Header__linkIcon {
  margin-right: $spacer-small-2x;
}

.Header__search {
  position: relative;
  z-index: $z-index-above;
  width: 303px;
  margin-right: 48px;
  margin-left: auto;

  @include tablet {
    position: absolute;
    left: $header-normal-search-offset;
    width: $header-normal-search-width;
    margin-right: 0;
    transition: width $transition-duration $transition-function, left $transition-duration $transition-function;
  }

  @include mobile {
    flex: 1;
    width: auto;
    margin-top: -8px;
    margin-right: $spacer-small;
    margin-bottom: -8px;
  }
}


.Header__shoppingCart {
  margin-left: auto;
}

.Header__link_delivery {
  @include tabletLand {
    display: none;
  }

  @include tablet {
    display: none;
  }
}

.Header_focused {
  .Header__search {
    @include tablet {
      position: absolute;
      left: $header-focused-search-offset;
      width: $header-focused-search-width;
    }
  }
}

.Header_fixed {
  position: fixed;
  top: 0;
  background: $color-surface-radial;
}

.Header__mobileMenu {
  position: fixed;
  z-index: $z-index-popup;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 76px 16px;
  background-color: $color-surface-light;
}

.Header__mobileMenuLink {
  @include caption_large_regular;
  margin-bottom: $spacer-medium;
  color: $color-text-primary;
}

.Header__cartCounter {
  position: absolute;
  margin-top: -10px;
  margin-left: 12px;
}
