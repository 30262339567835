@import '../../vars';
@import '../../common/components/ui/Button/ButtonMixins';

.FilterPage {
  display: grid;
  padding: $spacer-medium 0;
  grid-template-columns: repeat(16, 1fr);
  grid-gap: $spacer-medium;

  @include mobile {
    grid-template-columns: 100%;
    grid-row-gap: $spacer-small-2x;
  }
}

.FilterPage__head {
  display: flex;
  grid-column: 1 / span 8;
  grid-row: 1;

  @include mobile {
    grid-column: 1;
    grid-row: 1;
  }
}

.FilterPage__viewOptions {
  z-index: $z-index-dropdown;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  grid-column: 9 / span 8;
  grid-row: 1;

  @include tablet {
    grid-column: 8 / span 9;
  }

  @include mobile {
    grid-column: 1;
    grid-row: 2;
  }
}

.FilterPage__filter {
  grid-column: 1 / span 3;
  grid-row: 2 / span 2;

  @include tabletLand {
    grid-column: 1 / span 4;
  }

  @include lte_tablet {
    z-index: $z-index-dropdown;
  }

  @include mobile {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
  }
}

.FilterPage__content {
  grid-column: 4 / span 13;
  grid-row: 2;

  @include tabletLand {
    grid-column: 5 / span 12;
  }

  @include tablet {
    grid-column: 1 / span 16;
  }

  @include mobile {
    grid-row: 3;
    grid-column: 1;
  }
}

.FilterPage__cards {
  display: grid;

  @include mobile {
    margin-top: 16px;
  }
}

.FilterPage__cards_grid {
  width: 100%;
  grid-gap: $spacer-medium;
  grid-template-columns: repeat(3, 1fr);

  @include lte_tabletLand {
    justify-content: center;
    grid-template-columns: repeat(2, 304px);
  }

  @include tablet {
    grid-gap: $spacer-medium;
  }

  @include mobile {
    width: calc(100% + 32px);
    margin-left: -16px;
    grid-template-columns: 1fr;
    grid-row-gap: $spacer-small;
  }
}

.FilterPage__cards_nothingFound {
  grid-template-columns: 100%;
}

.FilterPage__cards_list {
  grid-template-columns: 1fr;
  grid-row-gap: $spacer-medium;

  @include mobile {
    width: calc(100% + 32px);
    margin-left: -16px;
  }
}

.FilterPage__sort {
  width: 180px;
  margin-right: $spacer-small;
  margin-left: auto;

  @include lte_tablet {
    order: 1;
    width: auto;
    margin-right: $spacer-small-2x;
  }

  @include tablet {
    margin-left: $spacer-medium;
  }

  @include mobile {
    margin-left: auto;
  }
}

.FilterPage__sortDropdown {
  @include lte_tablet {
    @include buttonPrimaryMixin;
    display: flex;
    align-items: center;
    justify-content: center;
    order: 1;
    width: 40px;
    height: 40px;
    border-radius: $radius-medium;
  }
}

.FilterPage__sortList {
  @include lte_tablet {
    top: -$spacer-small-x;
    right: 0;
    left: auto;
  }
}

.FilterPage__pagination {
  padding: $spacer-large-x 0;
  grid-row: 3;
  grid-column: 4 / span 13;

  @include tablet {
    grid-column: 1 / span 16;
  }

  @include mobile {
    padding: $spacer-large 0 $spacer-small-x;
    grid-column: 1;
    grid-row: 4;
  }
}