@import '../../../../vars';

.Footer {
  background: $color-surface-radial;
}

.Footer__content {
  @include adaptiveWidth;
  margin: 0 auto;
}
.Footer__contentMain {
  display: grid;
  padding: $spacer-large-2x 0;
  grid-template-columns: repeat(16, 1fr);
  grid-column-gap: $spacer-large;

  @include tabletLand {
    padding-top: $spacer-large-x;
  }

  @include tablet {
    padding: $spacer-large-x 0;
  }

  @include mobile {
    padding: 48px 0;
    grid-template-columns: 100%;
    grid-row-gap: 48px;
  }
}

.Footer__info {
  grid-column: 2 / span 3;

  @include tabletLand {
    grid-column: 2 / span 4;
  }

  @include tablet {
    grid-column: 1 / span 5;
  }

  @include mobile {
    grid-column: 1;
    grid-row: 3;
  }
}

.Footer__linksList {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  height: fit-content;
  grid-column: 6 / span 6;

  @include lte_tabletLand {
    flex-direction: column;
    grid-column: 6 / span 6;
  }

  @include tablet {
    grid-column: 7 / span 5;
  }

  @include mobile {
    grid-column: 1;
    grid-row: 1;
  }
}

.Footer__contacts {
  grid-column: 13 / span 4;

  @include tabletLand {
    grid-column: 12 / span 5;
  }

  @include tablet {
    grid-column: 12 / span 5;
  }

  @include mobile {
    grid-column: 1;
    grid-row: 2;
  }
}

.Footer__copyright {
  @include caption_small_medium;
  margin-top: $spacer-small;
  color: rgba($color-surface-light, 0.4);
}

.Footer__link {
  @include buttonReset;
  @include caption_large_medium;
  @include applyInvertedOutline;
  display: inline-block;
  width: 50%;
  margin-bottom: $spacer-small-x;
  margin-left: -$spacer-small-2x;
  padding: 0 $spacer-small-2x;
  transition: color $transition-duration $transition-function;
  text-decoration: none;
  color: $color-text-on-accent;
  border-radius: $radius-small;

  @include focusVisibleAndHover {
    color: $color-primary-on-dark;
  }

  @include lte_tabletLand {
    width: auto;
  }

  @include tabletLand {
    margin-bottom: $spacer-small;
  }

  @include tablet {
    margin-bottom: $spacer-small;
  }
}

.Footer__linkDelivery, .Footer__linkDocuments {
  @include lte_tabletLand {
    order: 1;
  }
}

.Footer__phone,
.Footer__address {
  @include caption_medium_medium;
  text-decoration: none;
  color: $color-text-on-accent;

  @include mobile {
    @include caption_large_medium;
  }
}

.Footer__phone {
  @include applyInvertedOutline;
  border-radius: $radius-small;
  outline: none;

  @include focusVisibleAndHover {
    color: $color-primary;
  }
}

.Footer__address {
  @include mobile {
    margin-top: $spacer-small;
    margin-bottom: 28px;
  }
}

.Footer__socials {
  margin-top: $spacer-small-x;
}

.Footer__socialLink {
  @include applyInvertedOutline;
  margin-right: $spacer-small-2x;
  margin-left: -$spacer-small-3x;
  padding: $spacer-small-3x $spacer-small-3x $spacer-small-2x;
  transition: opacity $transition-duration $transition-function;
  opacity: 0.4;
  color: $color-surface-light;
  border-radius: $radius-small;

  @include focusVisibleAndHover {
    opacity: 0.8;
  }
}

.Footer__logo {
  @include applyInvertedOutline;
  display: inline-block;
  margin: -$spacer-small-x;
  padding: $spacer-small-x;
  border-radius: $radius-small;
}

.FooterCopy {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $spacer-small 0 18px;
  border-top: 1px solid rgba($color-surface-light, .2);
}
.FooterCopy__link {
  @include buttonReset;
  @include caption_large_regular;
  transition: color, opacity;
  transition-timing-function: $transition-function;
  transition-duration: $transition-duration;
  text-decoration: none;
  opacity: .6;
  color: $color-text-on-accent;

  span {
    @include caption_large_medium;
  }

  @include focusVisibleAndHover {
    opacity: 1;
    color: $color-primary-on-dark;
  }
}