@import '../../../../vars';

.CheckboxList {
  display: inline-flex;
}

.CheckboxList_direction_vertical {
  flex-direction: column;

  .Checkbox + .Checkbox {
    margin-top: $spacer-small-3x;
  }
}

.CheckboxList_direction_horizontal {
  //@todo позднее, когда нарисуют
}

.CheckboxList__expand {
  @include buttonReset;
  @include caption_medium_medium;
  display: inline-flex;
  align-items: center;
  margin-top: $spacer-small-2x;
  text-align: left;
  color: $color-primary;
}

.CheckboxList__arrow_icon {
  margin-left: $spacer-small-2x;
  transition: transform 0.2s ease;
}

.CheckboxList_expanded {
  .CheckboxList__arrow_icon {
    transform: rotate(180deg);
  }
}