@import '../../../../vars';

.Link {
  @include buttonReset;
  text-decoration: none;
  color: inherit;

  @include focusVisibleAndHover {
    color: $color-primary;
  }
}
