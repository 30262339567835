@font-face {
  font-family: 'WixMadeforDisplay';
  src: url('./assets/fonts/WixMadeforDisplay/WixMadeforDisplay-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'WixMadeforDisplay';
  src:  url('./assets/fonts/WixMadeforDisplay/WixMadeforDisplay-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'WixMadeforDisplay';
  src: url('./assets/fonts/WixMadeforDisplay/WixMadeforDisplay-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}