@import './baseColors';
@import './baseSpacers';
@import './baseBreakpoints';

$scroller-card-width: 218px;
$scroller-gap: 24px;

@mixin ScrollerGrid($columns, $columnWidth) {
  display: grid;
  width: ($columnWidth + $spacer-small) * $columns + 24px + 100px;
  grid-template-columns: repeat($columns, $columnWidth);
  grid-column-gap: $scroller-gap;

  @include mobile {
    padding-left: $spacer-small;
    grid-column-gap: $spacer-small-2x;
  }
}
