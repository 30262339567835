@import '../../../../vars';

.OrderDetails {
  position: sticky;
  top: 88px;
  width: 100%;
  height: fit-content;
  border-radius: $radius-large;
  background-color: $color-surface-light;
  
  @include lte_tabletLand {
    top: auto;
    bottom: 0;
    margin-top: $spacer-medium;
    border-radius: 0;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.05);
  }
}

.OrderDetails__content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: $spacer-medium;
  color: $color-text-primary;

  @include lte_tabletLand {
    @include adaptiveWidth;
    align-items: center;
    flex-direction: row;
    margin: 0 auto;
    padding: $spacer-small 0 unquote('calc(#{$spacer-small} + env(safe-area-inset-bottom))');
  }
}

.OrderDetails__header {
  @include header_h4_medium;
  margin-bottom: 24px;

  @include lte_tabletLand {
    display: none;
  }
}

.OrderDetails__action {
  padding-top: $spacer-medium;
  border-top: 1px solid $color-background-light;

  @include lte_tabletLand {
    width: auto;
    min-width: 250px;
    padding-top: 0;
    border-top: none;
  }

  @include mobile {
    width: auto;
    min-width: revert;
    margin-left: auto;
  }
}

.OrderDetails__actionButton {
  width: 100%;

  @include mobile {
    width: auto;
  }
}

.OrderDetailsItem {
  display: flex;
  align-items: center;
  padding: $spacer-small 0;
  border-top: 1px solid $color-background-light;

  @include lte_tabletLand {
    align-items: flex-start;
    flex-direction: column;
    margin-right: $spacer-large-x;
    padding: 0;
    border-top: none;
  }

  @include mobile {
    margin-right: $spacer-small;
  }
}

.OrderDetailsItem__title {
  @include caption_large_regular;

  @include lte_tabletLand {
    @include caption_medium_regular;
  }
}

.OrderDetailsItem__value {
  @include caption_large_regular;
  flex: 1;
  text-align: right;

  @include lte_tabletLand {
    @include header_h4_medium;
    margin-top: $spacer-small-3x;
    text-align: left;
    color: $color-primary;
  }
}

.OrderDetailsItem_total {
  align-items: flex-start;
  flex-direction: column;
  padding: $spacer-medium 0;

  .OrderDetailsItem__title {
    @include caption_small_medium;
    text-transform: uppercase;
    color: $color-text-secondary;
  }

  .OrderDetailsItem__value {
    @include header_h2_medium;
    color: $color-primary;
  }

  @include lte_tabletLand {
    margin-left: auto;
    padding: 0;

    .OrderDetailsItem__title {
      @include caption_medium_bold;
      text-transform: none;
      color: $color-text-primary;
    }

    .OrderDetailsItem__value {
      @include header_h4_medium;
      margin-top: $spacer-small-3x;
      color: $color-primary;
    }
  }

  @include mobile {
    display: none;
  }
}