@import '../../../../vars';

.Amount {
  display: inline-block;
  text-align: center;
  color: $color-text-on-accent;
  background-color: $color-primary;
}

.Amount_size_small {
  @include caption_xsmall_bold;
  min-width: 16px;
  height: 16px;
  padding: 0 $spacer-small-3x/2;
  border-radius: $radius-large;
  box-shadow: 0 2px 3px rgba($color-primary, .4);
  line-height: 16px;
}

.Amount_size_medium {
  @include caption_xsmall_regular;
  height: 20px;
  padding: $spacer-small-3x/2 $spacer-small-2x;
  border-radius: $radius-xlarge;
}
