$color-white: #fff;
$color-black: #000;

$color-background-main: #dedee4;

$color-surface-light: #fff;
$color-surface-dark: #00192c;
$color-surface-inner: #318acb;
$color-surface-outer: #021b2e;
$color-surface-radial: radial-gradient(
    60% 431% at 80% 47%,
    rgba($color-surface-outer, 0.4) 0%,
    rgba($color-surface-inner, 0.024) 100%
  )
  $color-surface-dark;

$color-background-light: #f2f0ea;
$color-background-light-50rgb: #f7f7f7;
$color-background-light-50: rgba($color-background-light, 0.5);
$color-background-dark: #696865;
$color-background-gray: #f9f9f9;

$color-text-primary: #00192c;
$color-text-secondary: #6f7880;
$color-text-on-accent: #fff;

$color-border-dark: #00192c;
$color-border-light: linear-gradient(
  200deg,
  rgba($color-surface-light, 0.24) 0%,
  rgba($color-surface-light, 0.05) 100%
);
$color-border-input: #efefef;

$color-foil-black: #000;
$color-foil-green: #59f986;
$color-foil-yellow: #ffea2b;
$color-foil-blue: #0038ff;
$color-foil-gray: #0a0703;
$color-foil-cyan: #34a0ee;

$color-card-light-start: #dad8d6;
$color-card-light-end: #0a0703;

$color-card-foil: linear-gradient(
    30deg,
    rgba($color-foil-black, 0.04) 10%,
    rgba($color-foil-green, 0.24) 25%,
    rgba($color-foil-yellow, 0.24) 36%,
    rgba($color-foil-blue, 0.15) 49%,
    rgba($color-foil-gray, 0) 97.5%
  )
  rgba($color-foil-cyan, 0.19);
$color-card-light: linear-gradient(
  14deg,
  rgba($color-card-light-start, 0.3) 29%,
  rgba($color-card-light-end, 0.2) 93%
);
$color-card-fill: linear-gradient(14.19deg, rgba(218, 216, 214, 0.1) 28.99%, rgba(10, 7, 3, 0) 92.97%);

$color-primary: #fe724c;
$color-primary-hover: #ff5d31;
$color-primary-pressed: #f0572e;
$color-primary-on-dark: #d25f3f;

$color-secondary: #5a88ff;
$color-secondary-hover: #4075ff;
$color-secondary-pressed: #3165e9;
$color-secondary-logo: #f7a533;

$color-tag-1: #ffeae4;
$color-tag-2: #e6e8ea;
$color-tag-3: #9c9de0;
$color-error: #ff564e;

$color-shadow-warm: #decfcc;
$color-shadow-cold: #000;
$color-shadow-dropdown: #6a7a98;
$color-shadow-inner: #e8e8e8;
$color-blur-background: #6f7880;
