@import '../../vars';

.DeliveryAndPaymentsPage__header {
  @include header_h2_medium;
  margin: 0;

  @include lte_tabletLand {
    @include header_h3_medium;
  }

  @include mobile {
    @include header_h4_bold;
  }
}

.DeliveryAndPaymentsPage__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include lte_tablet {
    flex-wrap: wrap;
  }
}

.DeliveryAndPaymentsPage__block {
  max-width: 400px;
  margin-right: 40px;
  font-family: $font_family_wix;

  &:nth-child(n + 2) {
    margin-top: $spacer-large-x;
  }
}

.DeliveryAndPaymentsPage__payment {
  margin-top: $spacer-small;
}

.DeliveryAndPaymentsPage__contentHeader {
  @include header_h4_medium;

  @include mobile {
    @include caption_large_bold;
    margin-top: $spacer-medium;
  }
}

.DeliveryAndPaymentsPage__linkPrimary {
  color: $color-primary;
}

.DeliveryAndPaymentsPage__paymentSystems {
  padding-left: 16px;

  img {
    width: auto;
    height: 12px;
    margin-right: 4px;
    line-height: 16px;
  }
}
