@import 'src/vars';
@import 'src/common/components/ui/Button/ButtonMixins';

.FilterButton {
  @include buttonPrimaryMixin;
  position: relative;
  display: flex;
  overflow: initial;
  align-items: center;
  justify-content: center;
  order: 2;
  width: 40px;
  height: 40px;
  border-radius: $radius-medium;
}

.FilterButton__counter {
  position: absolute;
  top: -4px;
  right: -4px;
  color: $color-text-on-accent;
  background-color: $color-text-secondary;
}
