@import '../../vars';

.CardPage__layout {
}

.CardPage {
  padding: 40px $spacer-large 44px;
}
.CardPage__title {
  @include header_h2_medium;
  margin-top: 0;
}
.CardPage__annotation {
  @include caption_medium_regular;
  margin-bottom: $spacer-small;
  padding: $spacer-small-2x;
  text-align: center;
  color: rgba($color-surface-dark, 0.4);
  border-radius: $radius-large;
  background-color: $color-white;
}
.CardPage__cartLayout {
  display: grid;
  grid-template-columns: 1fr 280px;
  grid-template-rows: 1fr;
  grid-column-gap: $spacer-medium;
}

.CardPage__cart {
  > div + div {
    margin-top: $spacer-small;
  }
}

.CardPage__detailLayout {
}

.CardPage__detail {
  width: 280px;
  padding: $spacer-medium;
  border-radius: $radius-xlarge;
  background-color: $color-white;
  > * + * {
    border-top: 1px solid $color-background-light;
  }
}

.CardPage__detailHeader {
  @include header_h4_medium;
}

.CardPage__detailList {
  @include caption_large_regular;
  display: flex;
  justify-content: space-between;
  padding: $spacer-small 0;
}

.CardPage__detailResult {
  @include caption_small_medium;
  padding: $spacer-medium 0;
  color: $color-text-secondary;
}

.CardPage__ruble {
  @include header_h2_medium;
  color: $color-primary;
}

.CardPage__detailOrder {
  width: 100%;
  margin-top: 6px;
}

.CardPage__spacer {
  width: 100%;
  padding: $spacer-small 0;
  border-top: 1px solid $color-background-light;
}

.CardPage__detailTablet {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 88px;
  margin-top: $spacer-large-x;
  padding: 0 $spacer-large-x;
  background-color: $color-white;
}

.CartPage__emptyCart, .CartPage__loading {
  @include caption_large_regular;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: $spacer-large-2x $spacer-medium $spacer-large-3x;
  text-align: center;
  border-radius: $radius-xlarge;
  background-color: $color-surface-light;
}

.CartPage__emptyCartIcon {
  margin-bottom: $spacer-small;
  svg {
    width: 100px;
    height: 100px;
    color: $color-tag-2;
  }
}

.CartPage__emptyCartTitle {
  @include header_h4_medium;
  margin-bottom: $spacer-small;
}

.CartPage__emptyCartLink {
  @include caption_large_regular;
  a {
    color: $color-primary;
  }
}

.CartPage__loadingBlock, .CartPage__loading {
  text-align: center;
}

@include tabletLand {
  .CardPage {
    padding: $spacer-large-x $spacer-large 0;
  }

  .CardPage__cartLayout {
    display: block;
  }

  .CardPage__detailList {
    display: block;
  }

  .CardPage__ruble {
    @include header_h4_medium;
  }

  .CardPage__detailOrder {
    width: 250px;
    margin-top: 0;
  }
}

@include mobile {
  .CardPage {
    padding: $spacer-small 0 0;
  }

  .CardPage__title {
    padding-left: $spacer-small;
  }

  .CardPage__detailTablet {
    padding: 0 $spacer-small;
  }

  .CardPage__detailOrder {
    width: 192px;
  }

  .CardPage__detailList {
    @include caption_small_medium;
  }

  .CardPage__detailResult {
    @include caption_large_medium;
  }
}