@mixin CardImageSize($width, $height, $padding, $borderRadius) {
  width: $width;
  height: $height;
  padding: $padding;
  border-radius: $borderRadius;

  .CardImage__image {
    border-radius: $borderRadius;
  }

  .Card__preview {
    border-radius: $borderRadius;
  }
}

@mixin CardStickerSize($width, $height) {
  width: $width * 4;
  height: $height * 4;
  transform: scale(0.25);
}