$font_family_default: system-ui, /* macOS 10.11-10.12 */ -apple-system,
  /* Windows 6+ */ 'Segoe UI', /* Android 4+ */ 'Roboto',
  /* Ubuntu 10.10+ */ 'Ubuntu', /* Gnome 3+ */ 'Cantarell',
  /* KDE Plasma 5+ */ 'Noto Sans', /* fallback */ sans-serif,
  /* macOS emoji */ 'Apple Color Emoji', /* Windows emoji */ 'Segoe UI Emoji',
  /* Windows emoji */ 'Segoe UI Symbol', /* Linux emoji */ 'Noto Color Emoji';

$font_wix: "WixMadeforDisplay";
$font-family_norms: $font_wix, $font_family_default;
$font_family_wix: $font_wix, $font_family_default;

$font_weight_bold: 700;
$font_weight_medium: 500;
$font_weight_regular: 400;

$font_size_9: 9px;
$font_size_11: 11px;
$font_size_12: 12px;
$font_size_14: 14px;
$font_size_16: 16px;
$font_size_22: 22px;
$font_size_32: 32px;
$font_size_40: 40px;
$font_size_64: 64px;

$line_height_11: 11px;
$line_height_14: 14px;
$line_height_16: 16px;
$line_height_24: 24px;
$line_height_40: 40px;
$line_height_56: 56px;
$line_height_66: 66px;

//Header 1st level
@mixin header_h1_bold {
  font-family: $font_family_wix;
  font-size: $font_size_64;
  font-weight: $font_weight_bold;
  line-height: $line_height_66;
}

//Header 2nd level
@mixin header_h2_medium {
  font-family: $font_family_wix;
  font-size: 38px;
  font-weight: $font_weight_medium;
  line-height: $line_height_56;
}

//Header 3rd level
@mixin header_h3_bold {
  font-family: $font_family_wix;
  font-size: 30px;
  font-weight: $font_weight_bold;
  line-height: $line_height_40;
}

@mixin header_h3_medium {
  font-family: $font_family_wix;
  font-size: 30px;
  font-weight: $font-weight_medium;
  line-height: $line_height_40;
}

@mixin header_h3_regular {
  font-family: $font_family_wix;
  font-size: 30px;
  font-weight: $font_weight_regular;
  line-height: 24px;
}

//Header 4th level
@mixin header_h4_bold_caps {
  letter-spacing: 3px;
  text-transform: uppercase;
  font-family: $font_family_wix;
  font-size: 20px;
  font-weight: $font_weight_bold;
  line-height: $line_height_24;
}

@mixin header_h4_bold {
  font-family: $font_family_wix;
  font-size: 20px;
  font-weight: $font_weight_bold;
  line-height: $line_height_24;
}

@mixin header_h4_medium {
  font-family: $font_family_wix;
  font-size: 20px;
  font-weight: $font_weight_medium;
  line-height: $line_height_24;
}

// Caption Large
@mixin caption_large_bold {
  font-family: $font_family_wix;
  font-size: 15px;
  font-weight: $font_weight_bold;
  line-height: 20px;
}

@mixin caption_large_medium {
  font-family: $font_family_wix;
  font-size: 15px;
  font-weight: $font_weight_medium;
  line-height: 20px;
}

@mixin caption_large_regular {
  font-family: $font_family_wix;
  font-size: 15px;
  font-weight: $font_weight_regular;
  line-height: 20px;
}

//Caption Medium
@mixin caption_medium_bold {
  font-family: $font_family_wix;
  font-size: 13px;
  font-weight: $font_weight_bold;
  line-height: 20px;
}

@mixin caption_medium_medium {
  font-family: $font_family_wix;
  font-size: 13px;
  font-weight: $font_weight_medium;
  line-height: 20px;
}

@mixin caption_medium_regular {
  font-family: $font_family_wix;
  font-size: 13px;
  font-weight: $font_weight_regular;
  line-height: 20px;
}

//Caption Small
@mixin caption_small_bold_caps {
  letter-spacing: 2px;
  text-transform: uppercase;
  font-family: $font_family_wix;
  font-size: $font_size_12;
  font-weight: $font_weight_bold;
  line-height: 20px;
}

@mixin caption_small_medium {
  font-family: $font_family_wix;
  font-size: $font_size_12;
  font-weight: $font_weight_medium;
  line-height: $line_height_16;
}

@mixin caption_small_regular {
  font-family: $font_family_wix;
  font-size: $font_size_12;
  font-weight: $font_weight_regular;
  line-height: $line_height_16;
}

//Caption XSmall
@mixin caption_xsmall_bold {
  font-family: $font_family_wix;
  font-size: $font_size_9;
  font-weight: $font_weight_bold;
  line-height: $line_height_11;
}

@mixin caption_xsmall_regular {
  font-family: $font_family_wix;
  font-size: $font_size_11;
  font-weight: $font_weight_regular;
  line-height: $line_height_16;
}

//Base text
@mixin text_body {
  font-family: $font_family_wix;
  font-size: $font-size-16;
  font-weight: $font-weight-medium;
  line-height: $line-height-24;
}

@mixin text_accent {
  font-family: $font_family_wix;
  font-size: $font-size-16;
  font-weight: $font-weight-medium;
  line-height: $line-height-24;
}
