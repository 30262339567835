@mixin paginationButton {
  @include applyStandartOutline;
  min-width: 40px;
  padding: 6px;
  user-select: none;
  text-align: center;
  text-decoration: none;
  color: $color-primary;
  border: 2px solid $color-primary;
  border-radius: $radius-medium;
  background-color: $color-surface-light;

  &:hover {
    background-color: $color-pagination-hover;
  }

  &:active {
    background-color: $color-pagination-pressed;
  }
}