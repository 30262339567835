@import '../../../../vars';
@import './PaginationMixins';

$color-pagination-hover: #ffefea;
$color-pagination-pressed: #ffdfd6;

.Pagination {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  color: $color-primary;
}

.Pagination__page {
  @include buttonReset;
  @include caption_large_bold;
  @include paginationButton;

  & + & {
    margin-left: $spacer-small;

    @include mobile {
      margin-left: 6px;
    }
  }
}

.Pagination__dots {
  margin: 0 $spacer-small;

  @include mobile {
    margin: 0 -$spacer-small-3x;
  }
}

.Pagination__page_active {
  color: $color-text-on-accent;
  background-color: $color-primary;

  &:hover,
  &:active {
    color: $color-text-on-accent;
    background-color: $color-primary;
  }
}

.Pagination__arrow {
  @include buttonReset;
  @include paginationButton;
  margin-right: $spacer-small-x;
  padding: 2px;

  &:disabled {
    cursor: not-allowed;
    color: $color-text-on-accent;
    border-color: $color-tag-2;
    background-color: $color-tag-2;
  }

  @include mobile {
    margin-right: 0;

    &, &:hover, &:active {
      color: $color-text-on-accent;
      background-color: $color-primary;
    }
  }
}

.Pagination__page + .Pagination__arrow {
  margin-right: 0;
  margin-left: $spacer-small-x;
}

.Pagination__counter {
  @include caption_large_medium;
  margin: 0 $spacer-medium;
}

.Pagination__hotkeysTip {
  @include caption_medium_regular;
  width: 100%;
  margin-top: $spacer-small-x;
  text-align: center;
  color: $color-text-secondary;
}