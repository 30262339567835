@import '../../../../vars';

.CardState {
  display: grid;
  align-items: center;
  padding: $spacer-small-2x $spacer-small;
  border-radius: $radius-small;
  background-color: $color-background-light-50;
  grid-column-gap: $spacer-medium;

  & + & {
    margin-top: $spacer-small-2x;

    @include mobile {
      margin-top: $spacer-medium;
    }
  }

  @include mobile {
    padding: $spacer-small-3x 0;
    background-color: transparent;
    grid-column-gap: $spacer-small-x;
  }
}

.CardState__content {
  @include caption_large_medium;
  color: $color-text-primary;
}

.CardState__title {

}

.CardState__description {
  margin-top: 1px;
  color: $color-text-secondary;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
}

.CardState__descriptionStock {

}

.CardState__price {
  @include header_h4_medium;
  text-align: right;
  white-space: nowrap;

  @include mobile {
    font-size: 14px;
  }
}

.CardState__stock {
  color: $color-text-secondary;
  font-family: $font-family_norms;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
}

.CardState__button {
  display: block;

  @include mobile {
    text-align: right;
  }
}

.CardState_variantNormal, .CardState_variantPreorderState {
  grid-template-columns: 128px 1fr 256px;

  .CardState__price {
    white-space: nowrap;
    color: $color-primary;
  }

  @include tabletLand {
    grid-template-columns: 128px 1fr 256px;
  }

  @include mobile {
    grid-template-columns: 1fr min-content;

    .CardState__price {
      white-space: nowrap;
    }
  }
}

.CardState_variantPreorderState {
  .CardState__price {
    color: $color-secondary;
  }
}

.CardState_variantNonAvailable {
  grid-template-columns: 128px 1fr 256px;

  &.CardState_variantNonAvailableWithoutPrice {
    @include mobile {
      grid-template-columns: 1fr;
    }
  }

  @include tabletLand {
    grid-template-columns: 128px 1fr 256px;
  }

  @include mobile {
    grid-template-columns: 125px 1fr 40px;
  }
}

.CardState_variantInvalidPrice {
  grid-template-columns: 128px 1fr 256px;

  @include tabletLand {
    grid-template-columns: 128px 1fr 256px;
  }

  @include mobile {
    grid-template-columns: minmax(120px, max-content) 1fr 40px;
  }
}

.CardState_variantNonAvailable {
  .CardState__price {
    color: $color-secondary;
  }
}


.CardState_variantPreorder, .CardState_variantInvalidAllStates {
  grid-template-columns: 1fr 256px;

  @include mobile {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;

    .CardState__button {
      margin-top: $spacer-small-x;
    }
  }
}

.CardState_cantAddMore {
  .CardState__descriptionStock, .CardState__stock {
    color: $color-primary;
  }
}

.CardState_withDeleteButton {
  position: relative;
  padding-right: 64px;
  grid-template-columns: 100px 1fr 256px;

  &.CardState_variantPreorder, &.CardState_variantInvalidAllStates, &.CardState_variantNonAvailable {
    grid-template-columns: 1fr 256px;
  }

  .CardState__button {
    margin-top: 0;
  }

  @include tabletLand {
    grid-template-columns: 100px 1fr 256px;
  }

  @include mobile {
    padding-right: 32px;
    grid-template-columns: 1fr min-content;

    &.CardState_variantNonAvailable {
      grid-template-columns: 1fr min-content;
    }
  }
}

.CardState__deleteButton {
  position: absolute;
  top: 50%;
  right: 16px;
  height: 32px;
  margin-top: -16px;
  padding: 4px;
  cursor: pointer;
  transition: opacity $transition-duration $transition-function;
  opacity: 0.4;
  color: $color-text-secondary;
  border: none;
  line-height: 1;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  @include focusVisibleAndHover {
    opacity: 1;
  }

  @include mobile{
    right: -8px;
  }
}
