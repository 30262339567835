@import '../../../vars';
@import '../../../common/components/ui/Card/CardLoadingMixins';

.CardInfoLoading {
  overflow-x: hidden;

  @include mobile {
    grid-template-columns: 100%;
  }
}

.CardInfoLoading__image {
  @include applyDefaultCardLoading;
  display: block;
  width: 100%;
  height: 100%;
}

.CardInfoLoading__imageContainer {
  width: 100%;
  max-height: 528px;
  margin: 0;

  @include mobile {
    width: 184px;
    height: 250px;
    margin: 0 auto;
  }
}

.CardInfoLoading__setName {
  @include applyDefaultCardLoading;
  width: 30%;
  height: 12px;
}

.CardInfoLoading__title {
  @include applyDefaultCardLoading;
  width: 67%;
  height: 24px;
}

.CardInfoLoading__tag {
  @include applyDefaultCardLoading;
  display: inline-block;
  width: 100px;
  height: 24px;
  border-radius: 12px;
}

.CardInfoLoading__stateContainer {
  display: flex;
  align-items: center;
  width: 100%;
}

.CardInfoLoading__state {
  @include applyDefaultCardLoading;
  display: inline-block;
  width: 130px;
  height: 44px;

  & + & {
    margin-left: 12px;
  }
}

.CardInfoLoading__textRow {
  @include applyDefaultCardLoading;
  width: 350px;
  height: 24px;
  margin: 12px 0;
}

.CardInfoLoading__button {
  @include applyDefaultCardLoading;
  width: 220px;
  height: 56px;
}

.CardInfoLoading__price {
  @include applyDefaultCardLoading;
  width: 100px;
  height: 56px;
}