@import '../../../../vars';

.Popup {
  position: absolute;
  z-index: $z-index-popup;
  overflow: auto;
  max-height: 90vh;
  padding: 24px 32px;
  border: none;
  border-radius: 8px;
  outline: none;
  background: rgb(255, 255, 255);
  @include mobile {
    max-height: 100vh;
    padding-top: 56px;
    border-radius: 0;
  }
}

.PopupOverlay {
  position: fixed;
  z-index: $z-index-overlay;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  overflow: auto;
  align-items: center;
  justify-content: center;
  background-color: rgba($color-background-dark, 0.5);

  @include mobile {
    align-items: flex-start;
  }
}

.Popup__closeButton {
  @include buttonReset;
  position: absolute;
  top: 24px;
  right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  color: $color-text-primary;

  @include mobile {
    display: none;
  }
}

.Popup__closeIcon {
  width: 12px;
  height: 12px;
}
