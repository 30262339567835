@import '../../../../vars';

.BackLink {
  //display: flex;
  //align-items: center;
}

.BackLink__link {
  @include buttonReset;
  @include caption_large_medium;
  display: flex;
  align-items: center;
  color: $color-text-secondary;

  &:hover,
  &:focus {
    color: $color-text-primary;
    .BackLink__arrow {
      color: $color-text-primary;
    }
  }
}

.BackLink__arrow {
  display: block;
  width: 16px;
  height: 16px;
  margin: 0 $spacer-small-2x;
  transform: rotate(180deg);
  color: $color-text-secondary;
}
