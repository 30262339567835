@mixin buttonPrimaryMixin {
  color: $color-text-on-accent;
  background-color: $color-primary;

  &:hover {
    color: $color-text-on-accent;
    background-color: $color-primary-hover;
  }

  &:active {
    color: $color-text-on-accent;
    background-color: $color-primary-pressed;
  }
}

@mixin buttonSecondaryMixin {
  color: $color-text-on-accent;
  background-color: $color-secondary;

  &:hover {
    color: $color-text-on-accent;
    background-color: $color-secondary-hover;
  }

  &:active {
    color: $color-text-on-accent;
    background-color: $color-secondary-pressed;
  }
}

@mixin iconButtonMixin {
  color: $color-text-primary;
  background-color: transparent;

  &:hover {
    color: $color-primary-hover;
    background-color: transparent;
  }

  &:active {
    color: $color-primary-pressed;
    background-color: transparent;
  }
}
