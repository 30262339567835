@import '../../../../vars';

.StepBlock {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $spacer-large;
}

.StepBlock::before {
  position: absolute;
  z-index: $z-index-above;
  top: 24%;
  left: calc(124px / 2);
  width: calc(100% - 124px);
  content: '';
  border-bottom: 1px solid rgba($color-surface-dark, 0.2);
}

.StepBlock__step {
  @include buttonReset;
  width: 124px;
  cursor: default;
  text-align: center;
}

.StepBlock__stepCount {
  @include caption_medium_regular;
  position: relative;
  z-index: $z-index-above;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  min-width: 32px;
  height: 32px;
  margin: 0 auto $spacer-small-x;
  text-align: center;
  color: rgba($color-surface-dark, 0.2);
  border: 1px solid rgba($color-surface-dark, 0.2);
  border-radius: 50%;
  background-color: $color-white;
  .StepBlock__errorIcon {
    width: 10px;
    height: 10px;
  }
}

.StepBlock__stepAnnotation {
  @include caption_large_regular;
  transition: color $transition-function $transition-duration;
  text-align: center;
  color: rgba($color-surface-dark, 0.2);
}

.StepBlock__step.StepBlock_active {
  .StepBlock__stepCount {
    color: $color-surface-dark;
    border: 1px solid $color-background-light-50rgb;
    background-color: $color-background-light-50rgb;
  }
  .StepBlock__stepAnnotation {
    color: $color-surface-dark;
  }
}

.StepBlock_current {
  .StepBlock__stepAnnotation {
    @include caption_large_medium;
  }
}

.StepBlock__step.StepBlock_completed {
  .StepBlock__stepCount {
    color: $color-primary;
  }
}

.StepBlock__step_asLink {
  @include applyStandartOutline;
  cursor: pointer;
  border-radius: 4px;

  @include focusVisibleAndHover {
    .StepBlock__stepAnnotation {
      color: $color-primary;
    }
  }
}