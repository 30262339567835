@import '../../../../vars';
@import '../Input/InputMixins';

.Autocomplete {
  position: absolute;
  top: 48px;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px;
  border-radius: $radius-small;
  background-color: $color-surface-light;
  box-shadow: 0 12px 38px rgba(0, 0, 0, 0.16);
}

.Autocomplete__option {
  @include caption_large_medium;
  padding: $spacer-small-2x $spacer-small-x;
  cursor: pointer;
  transition: background-color $transition-duration $transition-function;
  text-align: left;
  text-decoration: none;
  color: $color-text-primary;
  border: none;
  border-radius: $radius-small;
  outline: none;

  & + & {
    margin-top: $spacer-small-3x;
  }

  @include focusVisibleAndHover {
    background-color: $color-background-light-50;
  }
}

.Autocomplete__option_focus {
  background-color: $color-background-light-50;
}