@mixin applyDefaultCardLoading {
  display: block;
  animation: card-loading 2s linear;
  animation-iteration-count: infinite;
  border-radius: $radius-medium;
  background-image: linear-gradient(90deg, white 0%, $color-tag-2 40%, white 100%);
  background-size: 400px;
}

@keyframes card-loading {
  0% {
    background-position-x: -355px;
  }

  100% {
    background-position-x: 500px;
  }
}

