@import './vars';

html {
  box-sizing: border-box;
  min-height: 100%;
  background: $color-surface-radial;
  font-family: $font-family-default;
  font-size: 16px;
  background-blend-mode: screen, normal;
  font-variant-ligatures: no-common-ligatures;
}

html,
body {
  width: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: $header-height-desktop;

  @include tablet {
    padding-top: $header-height-tablet;
  }

  @include mobile {
    padding-top: $header-height-mobile;
  }
}

#root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.ReactModal__Html--open,
.ReactModal__Body--open {
  overflow: hidden;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

:root {
  --contentWidth: 1280px;
}

@media (max-width: 1280px) {
  :root {
    --contentWidth: 100%;
  }
}
