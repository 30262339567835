@import '../../../../../vars';

.TabButton {
  @include caption_large_medium;
  min-width: 188px;
  padding: $spacer-small-2x $spacer-small-x;
  cursor: pointer;
  text-align: left;
  border: 2px solid $color-tag-2;
  border-radius: 8px;
  outline: none;
  &:hover {
    border: 2px solid $color-text-secondary;
  }
  &:active {
    border: 2px solid $color-primary-pressed;
  }
  &:disabled {
    color: $color-text-secondary;
    border: 2px solid $color-tag-2;
    background-color: $color-tag-2;
  }
}

.TabButton_active {
  border: 2px solid $color-primary;
  &:hover {
    border: 2px solid $color-primary-pressed;
  }
  &:active {
    border: 2px solid $color-primary-pressed;
  }
}
