@import '../../vars';

.FilterLayoutWrapper {
  width: 100%;
  background-color: $color-background-light;
}

.FilterLayout {
  @include adaptiveWidth;
  margin: 0 auto;
}
