@import '../../../../vars';

.CardAvailableBlock {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: $spacer-small-2x;
  padding: $spacer-small-2x $spacer-small;
  border-radius: $radius-small;
  background-color: $color-background-light-50;

  @include mobile {
    margin-bottom: $spacer-large;
    padding: 0;
    background-color: transparent;
  }
}

.CardAvailableBlock__label {
  @include caption_large_medium;
  width: 200px;
  color: $color-text-primary;

  @include mobile {
    @include caption_medium_medium;
    flex-grow: 1;
    width: auto;
  }
}

.CardAvailableBlock__condition {
  @include caption_medium_medium;
  margin-bottom: 2px;
  text-transform: uppercase;
}

.CardAvailableBlock__conditionName {
  color: $color-text-secondary;
  font-size: 13px;
  line-height: 15px;
}

.CardAvailableBlock__available {
  @include caption_small_regular;
  flex-grow: 1;
  color: $color-text-secondary;

  @include mobile {
    position: absolute;
    bottom: -16px;
  }
}

.CardAvailableBlock__available_warn {
  color: $color-error;
}

.CardAvailableBlock__amount {
  @include caption_large_medium;
  width: 150px;
  color: $color-text-secondary;

  @include mobile {
    display: none;
  }
}

.CardAvailableBlock__price {
  @include header_h4_medium;
  flex-grow: 1;
  margin-left: 24px;
  text-align: right;
  white-space: nowrap;
  color: $color-primary;

  @include mobile {
    @include caption_large_medium;
  }
}

.CardAvailableBlock__priceMax {
  color: $color-secondary;
}

.CardAvailableBlock__button {
  width: 152px;
  margin-left: 24px;
  text-align: right;

  @include mobile {
    width: fit-content;
    min-width: 100px;
  }
}

.CardAvailableBlock__addToBasketButton {
  white-space: nowrap;
  //width: 152px;
  //@include mobile {
  //  width: max-content;
  //  padding-top: 5px;
  //  padding-bottom: 5px;
  //}
}

.CardAvailableBlock__addToBasketSlider {
  display: flex;
  align-items: center;
  min-width: 150px;

  @include mobile {
    min-width: 95px;
    height: 34px;
    margin-left: auto;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.CardAvailableBlock__buttonPrice {
  margin-right: $spacer-small-x;
}

.CardAvailableBlock__sliderPrice {
  @include header_h4_medium;
  margin-right: $spacer-small;
  margin-left: auto;
  text-align: right;
  white-space: nowrap;
  color: $color-primary;

  @include mobile {
    font-size: 14px;
  }
}

.CardAvailableBlock_not_available {
  .CardAvailableBlock__label {
    flex-grow: 1;
  }

  .CardAvailableBlock__addToBasketButton {
    width: 100%;
    margin-left: 0;
    white-space: nowrap;
  }

  @include mobile {
    align-items: center;

    .CardAvailableBlock__addToBasketButton {
      width: auto;
    }

    .CardAvailableBlock__button {
      width: auto;
    }
  }
}

.CardAvailableBlock_preorderState {
  .CardAvailableBlock__sliderPrice {
    color: $color-secondary;
  }
}
