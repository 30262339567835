@import '../../../../../vars';

.CardOrderBlock {
  display: flex;
  align-items: center;
  padding: $spacer-small-2x $spacer-small;
  border-radius: $radius-small;
  background-color: $color-background-light-50;

  & + & {
    margin-top: $spacer-small-2x;
  }

  @include mobile {
    padding: 0;
    background-color: transparent;
  }
}

.CardOrderBlock__label {
  flex-grow: 1;
  width: 34%;
  color: $color-text-secondary;
  font-size: 13px;
  line-height: 15px;

  @include mobile {
    @include caption_medium_medium;
  }
}

.CardOrderBlock__condition {
  @include caption_medium_medium;
  margin-bottom: 2px;
  text-transform: uppercase;
  color: $color-text-primary;
}

.CardOrderBlock__info {
  display: flex;
  justify-content: flex-end;
}

.CardOrderBlock__amount {
  @include caption_large_medium;
}

.CardOrderBlock__price {
  @include header_h4_medium;
  min-width: 60px;
  margin: auto auto auto $spacer-large-x;
  text-align: right;
  color: $color-primary;

  @include mobile {
    @include caption_medium_medium;
  }
}
