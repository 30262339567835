/* Используется для элементов, которые нужно поместить позади базового слоя */
$z-index-behind: -1;

/* Используется для элементов, которые нужно вернуть на базовый слой */
$z-index-base: 0;

/* Используется для элементов, которые нужно поместить выше базового слоя */
$z-index-above: 1;

/* Слой для выпадающих меню, селектов и т.д. */
$z-index-dropdown: 100;

/* Слой для подложки  */
$z-index-overlay: 398;

/* Слой для диалоговых окон и т.д */
$z-index-popup: 399;

/* Слой для шапки */
$z-index-header: 397;
$z-index-header-mobile: 400;

/* Слой для закрепленной панели на экране */
$z-index-fixed: 401;
